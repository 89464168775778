import CONSTANTS from "src/utils/constants";

export interface GetTPReportFiltersRequest {
    reportName: string,
    params: ReportSpecificParams
}

interface ReportSpecificParams {
    period: string,
    accountingDay: string,
    workbookId?: string,
    providerCompanyCode?: string
}

export interface GetTPReportFiltersResponse {
    filterProperties: FilterProperty[],
    filterOptions: FilterOption[]
}

export interface FilterProperty {
    key: string,
    operators: string[]
}

export interface FilterOption {
    propertyKey: string,
    value: string
}

// Helper function to create filters payload for CWB Report
export function createGetTPReportFiltersRequestPayloadForCWBReport(
    accountingDay: string,
    period: string,
    workbook: string
) {
    return {
        reportName: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT,
        params: {
            accountingDay: accountingDay,
            period: period,
            workbookId: workbook
        }
    }
}