import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import CONSTANTS from 'src/utils/constants';
import TPEAction from '../../models/common/TPEAction';
import { CalculationBuilderState, getInitialICRSState, getInitialState } from './CalculationBuilderState';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import { CalculationExecution } from 'src/models/calculation-builder/CalculationExecution';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_WIZARD_STEPS: new TPEAction('SET_WIZARD_STEPS'),
    SET_SELECTED_WIZARD_STEP: new TPEAction('SET_SELECTED_WIZARD_STEP'),
    DISABLE_WIZARD_STEP_AND_STEPS_AHEAD: new TPEAction('DISABLE_WIZARD_STEP_AND_STEPS_AHEAD'),
    SET_CALCULATION: new TPEAction('SET_CALCULATION'),
    SET_ICRS_CALCULATION: new TPEAction('SET_ICRS_CALCULATION'),
    SET_NEW_CALCULATION_NUMBER: new TPEAction('SET_NEW_CALCULATION_NUMBER'),
    SET_CALCULATION_VERSIONS: new TPEAction('SET_CALCULATION_VERSIONS'),
    SET_SELECTED_CALCULATION_VERSION: new TPEAction('SET_SELECTED_CALCULATION_VERSION'),
    SET_TP_LOV: new TPEAction('SET_TP_LOV'),
    SET_SEVEN_SEGMENT_LOV: new TPEAction('SET_SEVEN_SEGMENT_LOV'),
    SET_CALCULATION_UPLOAD_S3_KEY: new TPEAction('SET_CALCULATION_UPLOAD_S3_KEY'),
    SET_DATA_SOURCES: new TPEAction('SET_DATA_SOURCES'),
    SET_CALCULATION_STEPS: new TPEAction('SET_CALCULATION_STEPS'),
    SET_CALCULATION_VERSIONS_NEED_REFRESH: new TPEAction('SET_CALCULATION_VERSIONS_NEED_REFRESH'),
    SET_CREATE_RENAME_CALCULATION_TEMPLATE_PAYLOAD: new TPEAction('SET_CREATE_RENAME_CALCULATION_TEMPLATE_PAYLOAD'),
    SET_TEMPORARY_MESSAGE: new TPEAction('SET_TEMPORARY_MESSAGE'),
    SET_TEMPLATE_METADATA: new TPEAction('SET_TEMPLATE_METADATA'),
    SET_VALIDATE_CALC_STEPS: new TPEAction('SET_VALIDATE_CALC_STEPS'),
    SET_ARE_CALC_STEPS_VALID: new TPEAction('SET_ARE_CALC_STEPS_VALID'),
    SET_LINKED_CLIS: new TPEAction('SET_LINKED_CLIS'),
    SET_UNLINK_CALCULATION_PAYLOAD: new TPEAction('SET_UNLINK_CALCULATION_PAYLOAD'),
    SET_GET_TEMPLATES_FOR_WORKBOOK: new TPEAction('SET_GET_TEMPLATES_FOR_WORKBOOK'),
    SET_AVAILABLE_TEMPLATES: new TPEAction('SET_AVAILABLE_TEMPLATES'),
    SET_LINK_CALCULATION_PAYLOAD: new TPEAction('SET_LINK_CALCULATION_PAYLOAD'),
    SET_CALCULATION_CONFIGURATION_STATE: new TPEAction('SET_SELECTED_CONFIGURATION_STATE'),
    SET_REFRESH_JOURNALS_FLAG: new TPEAction('SET_REFRESH_JOURNALS_FLAG'),
    SET_REFRESH_CALC_STEPS_FLAG: new TPEAction('SET_REFRESH_CALC_STEPS_FLAG'),
    SET_EXECUTE_CLI_PAYLOAD: new TPEAction('SET_EXECUTE_CLI_PAYLOAD'),
    SET_EXECUTE_CLI_TASK: new TPEAction('SET_EXECUTE_CLI_TASK'),
    SET_REFRESH_DATASOURCES_FLAG: new TPEAction('SET_REFRESH_DATASOURCES_FLAG'),
    SET_EXECUTION_PERIOD: new TPEAction('SET_EXECUTION_PERIOD'),
    SET_CALCULATION_EXECUTION: new TPEAction('SET_CALCULATION_EXECUTION'),
    SET_GENERATE_REVIEW_JOURNALS_PAYLOAD: new TPEAction('SET_GENERATE_REVIEW_JOURNALS_PAYLOAD'),
    SET_GENERATE_REVIEW_JOURNALS_STATUS: new TPEAction('SET_GENERATE_REVIEW_JOURNALS_STATUS'),
    SET_REVIEW_JOURNALS: new TPEAction('SET_REVIEW_JOURNALS'),
    SET_ATP_USERS: new TPEAction('SET_ATP_USERS'),
    SET_EXECUTION_RESULT_LINES: new TPEAction('SET_EXECUTION_RESULT_LINES'),
    SET_REVERSAL_EXECUTION_RESULT_LINES: new TPEAction('SET_REVERSAL_EXECUTION_RESULT_LINES'),
    SET_REFRESH_CALCULATION_METADATA_FLAG: new TPEAction('SET_REFRESH_CALCULATION_METADATA_FLAG'),
    SET_REFRESH_CLI_DETAILS_FLAG: new TPEAction('SET_REFRESH_CLI_DETAILS_FLAG'),
    REFRESH_ALL_CALCULATION_INFORMATION: new TPEAction('REFRESH_ALL_CALCULATION_INFORMATION'),
    CHECK_AND_REFRESH_CALCULATION_INFORMATION: new TPEAction('CHECK_AND_REFRESH_CALCULATION_METADATA'),
    SET_REFRESH_REVIEW_JOURNALS_FLAG: new TPEAction('SET_REFRESH_REVIEW_JOURNALS_FLAG'),
    RESET_VIEW_MODE: new TPEAction('RESET_VIEW_MODE'),
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function calculationBuilderReducer(state: CalculationBuilderState, action: TPEAction) : CalculationBuilderState {
    switch (action.type) {
        case ACTIONS.RESET.type:
            const { isICRSRecord } = action.payload;
            return isICRSRecord? getInitialICRSState() : getInitialState();
        case ACTIONS.SET_WIZARD_STEPS.type:
            return {
                ...state,
                wizardSteps: action.payload
            };
        case ACTIONS.SET_CALCULATION.type:
            return {
                ...state,
                calculation: action.payload,
                cliDetailsNeedRefresh: false,
            };
        case ACTIONS.SET_NEW_CALCULATION_NUMBER.type:
            return {
                ...state,
                newCalculationNumber: action.payload,
            };
        case ACTIONS.SET_CALCULATION_VERSIONS.type:
            const allVersions = action.payload as number[];
            const versions = allVersions.filter(x => x !== -2);
            const updatedVersion = versions.length > 0? versions[0] : undefined;
            return {
                ...state,
                calculationVersions: versions,
                hasSavedStagingVersion: allVersions.includes(-2),
                calculationVersionsNeedRefresh: false,
                calculationMetaDataNeedsRefresh: updatedVersion != state.selectedCalculationVersion,
                selectedCalculationVersion: updatedVersion,
            };
        case ACTIONS.SET_SELECTED_CALCULATION_VERSION.type:
            return {
                ...state,
                selectedCalculationVersion: action.payload,
            };
        case ACTIONS.SET_CALCULATION_VERSIONS_NEED_REFRESH.type:
            return {
                ...state,
                calculationVersionsNeedRefresh: action.payload,
            };
        case ACTIONS.SET_TP_LOV.type: {
            return {
                ...state,
                tpLOV: action.payload,
            };
        }
        case ACTIONS.SET_SEVEN_SEGMENT_LOV.type: {
            const { segment, lov } = action.payload;
            const lovMap = state.sevenSegmentLOV != undefined ? new Map(state.sevenSegmentLOV) : new Map<string, string[]>();
            lovMap.set(segment, lov);
            return {
                ...state,
                sevenSegmentLOV: lovMap,
            }
        }
        case ACTIONS.SET_CALCULATION_UPLOAD_S3_KEY.type: {
            return{
                ...state,
                calculationUploadS3Key: action.payload,
            }
        }
        case ACTIONS.SET_DATA_SOURCES.type:
            return {
                ...state,
                dataSourceRecords: action.payload,
            };
        case ACTIONS.SET_CALCULATION_STEPS.type:
            return {
                ...state,
                steps: action.payload,
            };
        case ACTIONS.DISABLE_WIZARD_STEP_AND_STEPS_AHEAD.type:
            const { wizardSteps } = state;
            // Disabling this wizard step and steps ahead
            wizardSteps.forEach(x => { if (x.number >= action.payload) x.isEnabled = false}) 
            return {
                ...state,
                wizardSteps: [...wizardSteps],
            };
        case ACTIONS.SET_SELECTED_WIZARD_STEP.type:
            if (state.selectedWizardStep) {
                state.selectedWizardStep.isSelected = false;
            }
            if (action.payload) {
                action.payload.isSelected = true;
                action.payload.isEnabled = true;
            }
            for (var i = 0; i < state.wizardSteps.indexOf(action.payload); i++) {
                state.wizardSteps[i].isEnabled = true;
            }
            return {
                ...state,
                selectedWizardStep: action.payload
            };
        case ACTIONS.SET_CREATE_RENAME_CALCULATION_TEMPLATE_PAYLOAD.type:
            return {
                ...state,
                createRenameTemplatePayload: action.payload,
            };
        case ACTIONS.SET_TEMPORARY_MESSAGE.type: 
            return {
                ...state,
                temporaryMessage: action.payload,
            };            
        case ACTIONS.SET_TEMPLATE_METADATA.type: {
            const metaData = action.payload as CalculationTemplateMetaData;
            const { viewMode, isEditingTemplate } = getViewMode(state.calculation?.calculationNumber || '', metaData, state.calculationExecution);
            return {
                ...state,
                calculationTemplateMetaData: metaData,
                viewMode: viewMode,
                isEditingTemplate: isEditingTemplate,
                calculationMetaDataNeedsRefresh: false,
            }
        }
        case ACTIONS.SET_VALIDATE_CALC_STEPS.type: {
            return {
                ...state,
                validateCalculationSteps: action.payload,
            }
        }
        case ACTIONS.SET_ARE_CALC_STEPS_VALID.type: {
            return {
                ...state,
                areCalculationStepsValid: action.payload,
            }
        };
        case ACTIONS.SET_LINKED_CLIS.type: {
            return {
                ...state,
                linkedCLIs: [...action.payload],
            }
        };
        case ACTIONS.SET_UNLINK_CALCULATION_PAYLOAD.type: {
            return {
                ...state,
                unlinkCalculationPayload: action.payload,
            }
        };
        case ACTIONS.SET_GET_TEMPLATES_FOR_WORKBOOK.type: {
            return {
                ...state,
                getTemplatesForWorkbook: action.payload,
            }
        }
        case ACTIONS.SET_AVAILABLE_TEMPLATES.type: {
            return {
                ...state,
                availableTemplates: action.payload,
            }
        };
        case ACTIONS.SET_LINK_CALCULATION_PAYLOAD.type: {
            return {
                ...state,
                linkCalculationPayload: action.payload,
            }
        };
        case ACTIONS.SET_CALCULATION_CONFIGURATION_STATE.type: {
            return {
                ...state,
                calculationConfigurationState: action.payload,
            }
        }
        case ACTIONS.SET_REFRESH_JOURNALS_FLAG.type: {
            return {
                ...state,
                journalsNeedRefresh: action.payload,
            }
        }
        case ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.type: {
            return {
                ...state,
                calcStepsNeedRefresh: action.payload,
            }
        }
        case ACTIONS.SET_EXECUTE_CLI_PAYLOAD.type: {
            return {
                ...state,
                executeCLIPayload: action.payload,
                showCLIExecutionStatus: action.payload == null ? state.showCLIExecutionStatus : true,
                cliExecutionInProgress: action.payload == null ? state.cliExecutionInProgress : true,
            }
        }
        case ACTIONS.SET_GENERATE_REVIEW_JOURNALS_PAYLOAD.type: {
            return {
                ...state,
                generateReviewJournalsPayload: action.payload,
                generatingReviewJournalsStatus: action.payload == null? '' : CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.STARTED,            
            }
        }
        case ACTIONS.SET_EXECUTE_CLI_TASK.type: {
            let inProgress = state.cliExecutionInProgress;
            if (action.payload != null && ['Calculation Succeeded', 'Calculation Failed'].includes(action.payload.status)) {
                inProgress = false;
            }
            return {
                ...state,
                executeCLITask: action.payload,
                cliExecutionInProgress: inProgress,
            }
        }
        case ACTIONS.SET_REFRESH_DATASOURCES_FLAG.type: {
            return {
                ...state,
                dataSourcesNeedRefresh: action.payload,
            }
        }
        case ACTIONS.SET_EXECUTION_PERIOD.type: {
            return {
                ...state,
                executionPeriod: action.payload,
                executionPeriodWasTouched: true,
            }
        }
        case ACTIONS.SET_REVIEW_JOURNALS.type: {
            return {
                ...state,
                reviewJournals: action.payload,
                // Status cannot be set as SUCCEEDED if there are no review journals
                generatingReviewJournalsStatus: (action.payload as any[]).length === 0? '' : CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.SUCCEEDED,
                reviewJournalsNeedRefresh: false,
            }
        }
        case ACTIONS.SET_GENERATE_REVIEW_JOURNALS_STATUS.type: {
            return {
                ...state,
                generatingReviewJournalsStatus: action.payload,
            }
        }
        case ACTIONS.SET_CALCULATION_EXECUTION.type: {
            const { viewMode, isEditingTemplate } = getViewMode(state.calculation?.calculationNumber || '', state.calculationTemplateMetaData, action.payload);

            return {
                ...state,
                calculationExecution: action.payload,
                isEditingTemplate: isEditingTemplate,
                viewMode: viewMode,
                calcStepsNeedRefresh: true,
                dataSourcesNeedRefresh: true,
                journalsNeedRefresh: true
            }
        }
        case ACTIONS.SET_EXECUTION_RESULT_LINES.type: {
            return {
                ...state,
                executionResultLines: action.payload,
            }
        }
        case ACTIONS.SET_REVERSAL_EXECUTION_RESULT_LINES.type: {
            return {
                ...state,
                reversalExecutionResultLines: action.payload
            }
        }
        case ACTIONS.SET_REFRESH_CALCULATION_METADATA_FLAG.type: {
            return {
                ...state,
                calculationMetaDataNeedsRefresh: action.payload,
            }
        }
        case ACTIONS.SET_REFRESH_CLI_DETAILS_FLAG.type: {
            return {
                ...state,
                cliDetailsNeedRefresh: action.payload,
            }
        }
        case ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION.type: {
            return {
                ...state,
                calculationMetaDataNeedsRefresh: true,
                calculationVersionsNeedRefresh: true,
                cliDetailsNeedRefresh: true
            }
        }
        case ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION.type: {
            if (state.calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.ACTIVE) {
                return {
                    ...state,
                    calculationVersionsNeedRefresh: true,
                    cliDetailsNeedRefresh: true,
                }
            }
            return state;
        }
        case ACTIONS.SET_REFRESH_REVIEW_JOURNALS_FLAG.type: {
            return {
                ...state,
                reviewJournalsNeedRefresh: action.payload
            }
        }
        case ACTIONS.RESET_VIEW_MODE.type: {
            const { viewMode, isEditingTemplate } = getViewMode(state.calculation?.calculationNumber || '', state.calculationTemplateMetaData, state.calculationExecution);
            return {
                ...state,
                viewMode: viewMode,
                isEditingTemplate: isEditingTemplate
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}

function getViewMode(calculationNumber: string, metadata?: CalculationTemplateMetaData, calculationExecution?: CalculationExecution) {
    let localViewMode = '';
    let localIsEditingTemplate = false;

    if ((calculationExecution != null && calculationExecution.calculationExecutionId != null) || !getPermissions(AppModules.CALCULATION_BUILDER).canEdit) {
        localViewMode = CONSTANTS.VIEW_MODE.FROZEN;
    } else {
        switch(metadata?.calculationStatus){
            case CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW:
                localViewMode = CONSTANTS.VIEW_MODE.READ_ONLY;
                break;
            case CONSTANTS.CALCULATION_STATUS.INACTIVE:
            case CONSTANTS.CALCULATION_STATUS.TERMINATED:
                localViewMode = CONSTANTS.VIEW_MODE.FROZEN;
                break;
            default:
                localViewMode = CONSTANTS.VIEW_MODE.EDITABLE;
        }
    }

    const editableViewMode = localViewMode === CONSTANTS.VIEW_MODE.EDITABLE;

    if ((calculationExecution == null || calculationExecution.calculationExecutionId == null) && metadata != null && metadata.templateId != null) {
        localViewMode = editableViewMode ? (metadata.templateEditCalculation == calculationNumber ? CONSTANTS.VIEW_MODE.EDITABLE 
                        : CONSTANTS.VIEW_MODE.TEMPLATE_READ_ONLY) : localViewMode;
        localIsEditingTemplate = editableViewMode && metadata.templateEditCalculation == calculationNumber;
    }

    return { viewMode: localViewMode, isEditingTemplate: localIsEditingTemplate};
}