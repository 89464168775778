import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import YtdPeriodBalance from "src/models/periodCharges/YtdPeriodBalance";
import PeriodChargesLOVsResponse from "src/models/periodCharges/PeriodChargesLOVsResponse";


export default class PeriodChargesService {

    getYtdBalances(payload: string) : [YtdPeriodBalance[], boolean, string] {
        const [results, setResults] = useState([] as YtdPeriodBalance[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    //const response = await apiService.getYtdBalances(payload);
                    //const json = await response.json() as YtdPeriodBalance[];
                    setResults([{
                            calculationNumber: '00123400.01_AB-CD',
                            currency: 'USD',
                            beginningBalance: 20,
                            month: 'July',
                            year: 2024,
                            updatedOn: new Date(),
                            approvalStatus: 'Pending approval',
                            assignee: "chashank"
                        },{
                            calculationNumber: '0123450.01_BC-DE',
                            currency: 'USD',
                            beginningBalance: 5,
                            month: 'May',
                            year: 2024,
                            updatedOn: new Date(),
                            approvalStatus: 'Pending approval',
                            assignee: "anirvis"
                        },{
                            calculationNumber: '00123456.01_CD-EF',
                            currency: 'USD',
                            beginningBalance: 10,
                            month: 'Jan',
                            year: 2025,
                            updatedOn: new Date(),
                            approvalStatus: 'Approved',
                            approvedOn: new Date(),
                            assignee: "chashank"
                        },
                    ]);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    getLOVs(): [PeriodChargesLOVsResponse | null, boolean, string]{
        const [results, setResults] = useState(null as PeriodChargesLOVsResponse | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    //const response = await apiService.getYtdBalances(payload);
                    //const json = await response.json() as YtdPeriodBalance[];
                    setResults({
                        years: ["2025","2024","2023"],
                        assignees: ["payara", "anirvis", "chashank"],
                        statuses: ["Pending approval", "Approved", "No approval needed","Overdue"],
                        clis: ["00123400.01_AB-CD", "0123450.01_BC-DE", "00123456.01_CD-EF"]
                    });
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }, []);

        return [results, loading, error];
    }
}