import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { GLOBAL_ACTIONS } from '../../services/global/GlobalReducer';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import TPEAction from 'src/models/common/TPEAction';
import { Button, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { GlobalAppContext } from '../App';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from 'src/components/TPENavigator';
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";
import YtdBalancesGrid from './YtdBalancesGrid';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';


export default function PeriodChargesView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [navigateURL, setNavigateURL] = useState('');

    const navigateToCalcBuilder = (recordId: string) => {
        const url = CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId);
        setNavigateURL(url);
    };

    const [ytdBalances, ytdBalancesLoading, ytdBalancesError] = services.periodChargesService.getYtdBalances("test");
    const [lovs, lovsLoading, lovsError] = services.periodChargesService.getLOVs();

    const [ytdBalancesGidItems, setYtdBalancesGidItems] = useState(ytdBalances);
    const [showComingSoonModal, setShowComingSoonModal] = useState(false);

    useEffect(() => {
        if (ytdBalances.length === 0){
            return;
        }
        setYtdBalancesGidItems(ytdBalances);
    }, [ytdBalances])
    
    
    const skipFilterValue = (value:string | null) => {
        return value == null || value === "" || value === "All";
    }
    
    const onFilterChanged = (filterValues: {selectedCLI: string, selectedYear: string, selectedAssignee: string, selectedStatus: string}) => {
        if (ytdBalances.length === 0){
            return;
        }
        setYtdBalancesGidItems(ytdBalances.filter(x => 
            (skipFilterValue(filterValues.selectedAssignee) || filterValues.selectedAssignee === x.assignee) &&
            (skipFilterValue(filterValues.selectedCLI) || filterValues.selectedCLI === x.calculationNumber) &&
            (skipFilterValue(filterValues.selectedStatus) || filterValues.selectedStatus === x.approvalStatus) && 
            (skipFilterValue(filterValues.selectedYear) || filterValues.selectedYear === x.year.toString()) ))
    }

    const onExportAllCLIsClicked = () => {
        setShowComingSoonModal(true);
    }
    
    const onApproveBalanceClicked = () => {
        setShowComingSoonModal(true);
    }


    return <Container className="polaris-content-container">
        <TPENavigator path={navigateURL} />
        <YtdBalancesGrid data={ytdBalancesGidItems} filterLOVs={lovs} onFilterChanged={onFilterChanged} onApproveBalance={onApproveBalanceClicked} onExportAllCLIs={onExportAllCLIsClicked}></YtdBalancesGrid>
        <TPEBasicModal title='Feature coming soon' action={ACTION_TYPE.OK_ONLY} visible={showComingSoonModal} onConfirm={() => setShowComingSoonModal(false)} onCancel={() => setShowComingSoonModal(false)}>This feature is coming soon!</TPEBasicModal>
    </Container>
}