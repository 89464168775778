import React, {useEffect, useState} from 'react';
import {TPEReactTable} from '../../shared/TPEReactTable';
import CONSTANTS from '../../../utils/constants';
import {Box, Button, ButtonDropdown, ButtonDropdownProps, Icon, Link, Popover, StatusIndicator, Toggle} from '@amzn/awsui-components-react';
import {StepsContext} from './StepsContainer';
import {ACTIONS} from '../../../services/calculation-builder/CalculationStepsReducer';
import CalculationStep from '../../../models/calculation-builder/CalculationStep';
import CalculationTPEEntry from 'src/models/calculation-builder/CalculationTPEntry';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import TPEAction from 'src/models/common/TPEAction';
import FormulaExpressionToolbar from '../../shared/FormulaExpressionToolbar';
import {Row} from 'react-table';
import ServiceCollection from 'src/services/ServiceCollection';
import DomUtils from 'src/utils/DomUtils';
import ArrayUtils from 'src/utils/arrayUtils';
import {ACTION_TYPE, TPEBasicModal} from 'src/components/shared/TPEBasicModal';
import ReadOnlyCalculationService from 'src/services/calculations/ReadOnlyCalculationService';
import StepsTPEntriesSelector from './StepsTPEntriesSelector';
import {CalcBuilderComment} from 'src/models/calculation-builder/CalcBuilderComment';
import CommentsModal from '../CommentsModal';
import TemporaryMessage from 'src/models/common/TemporaryMessage';
import {CalculationBuilderContext} from '../CalculationBuilderView';
import {renderExpressionParts} from "src/components/shared/FormulaExpressionFunctionComponents";
import {SavingStatus} from 'src/models/common/SavingStatus';
import {openStandardAllocationModal, renderInfo} from "src/components/calculation-builder/data-sources/GridCommon";
import StringUtils from "src/utils/stringUtils";
import {ACTIONS as CALC_BUILDER_ACTIONS} from "src/services/calculation-builder/CalculationBuilderReducer";

type AutoSuggesstListItem = {
    name: string,
    value: string,
    type: string,
    hasHeader?:boolean,
}

const AUTOSUGGEST_TRIGGER = '@'

// Cell Editors
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any}) => {
    const [value, setValue] = React.useState(initialValue);
    const [textAreaValue, setTextAreaValue] = React.useState(`${initialValue}`);
    const tpeEntriesApplied = editorsData.tpeEntriesApplied as Set<CalculationTPEEntry>;
    const standardAllocationIds = editorsData.standardAllocationIds as Set<string>;

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
        setTextAreaValue(initialValue)
    }, [initialValue])


    const onTextAreaChanged = function(e:any){
        setTextAreaValue(e.target.value)
    }

    const onCaretPositionChange = (e: any) => {
        editorsData.onTextAreaCaretPositionChanged(e);
    }

    const onStepNameChanged = (e: any) => {
        const previousValue = value.trim();
        setValue(e.detail.value);
        if (previousValue !== '') {
            const entry = Array.from(tpeEntriesApplied).find(e => e === previousValue);
            if (entry) {
                tpeEntriesApplied.delete(entry);
            }
        }
    }

    const onBlur = () => {
        onRowUpdated(index, id, value)
    }

    const onTextAreaBlur = (e: any) => {
        if (StringUtils.isNullOrEmpty(textAreaValue) || textAreaValue.indexOf(AUTOSUGGEST_TRIGGER) >= 0){
            return;
        }
        onRowUpdated(index, id, textAreaValue);
    }

    const getTPEntryDDOptions = (tpEntries: any[]) => {
        return tpEntries.filter(x => !tpeEntriesApplied.has(x.value)) as any[];
    }



    const step = original as CalculationStep;
    const allSteps = editorsData.stepsList as CalculationStep [];
    if (step.isBeingEdited){
        switch (id) {
            case CONSTANTS.CALCULATION_STEP_FIELDS.NAME:
                return <StepsTPEntriesSelector key={`${id}_${index}`}
                    onValueChanged={onStepNameChanged}
                    onBlur={onBlur}
                    value={value}
                    options={getTPEntryDDOptions(editorsData.tpeEntries)}
                />

            case CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW:
                const AutoCompleteItem = ({ entity: { name, type, hasHeader } }: {entity: AutoSuggesstListItem}) => (
                    <React.Fragment>
                      {hasHeader ? (
                        <div className="dropdown-item-header">{`${type}`}</div>
                      ) : null}
                      <div className="dropdown-item">{`${name}`}</div>
                    </React.Fragment>
                );

                let stepsAbove = allSteps.filter(x => parseFloat(x.visibleSequence || '0') < parseFloat(step.visibleSequence || '0'))
                    .map(x => ({name: `S${x.visibleSequence}.${x.stepName}`, value: x.stepName, type: 'Previous Steps', id: x.stepId}))
                if (step.subRows?.length || 0 > 0 ) {
                    stepsAbove = stepsAbove.concat(step.subRows?.map(x => ({name: `S${x.visibleSequence}.${x.stepName}`, value: x.stepName, type: 'Sub Steps', id: x.stepId})) || []);
                }
                // Remove the parent from stepsAbove to avoid a circular reference
                if (step.isSubstep) {
                    stepsAbove = stepsAbove.filter(s => step.parentId !== s.id)
                }
                const suggestionsList:AutoSuggesstListItem[] = stepsAbove.concat(editorsData.suggestionsList);
                

                return <ReactTextareaAutocomplete key={`${id}_${index}`}
                    readOnly={step.appliedTPEntry?.value != null}
                    className="formulaEditorTextarea" value={textAreaValue}
                    onChange={onTextAreaChanged}
                    onCaretPositionChange={onCaretPositionChange}
                    onBlur={onTextAreaBlur}
                    loadingComponent={() => <span>Loading</span>}
                    minChar={0}
                    containerStyle={{
                        marginTop: 20,
                        width: "100%",
                        height: 60,
                        margin: "0 auto"
                    }}
                    trigger={{
                        "@": {
                            dataProvider: (token) => {
                                // TODO: Create a separate symbol for standard allocation
                                const standardAllocationFormulas = ["getStandardAllocation()","getStandardAllocationTotal()"];
                                if (standardAllocationFormulas.indexOf(step.expression.trim()) >= 0) {
                                    suggestionsList.push(...Array.from(standardAllocationIds.values()).map(x => ({name: x, value: x, type: 'Standard Allocation Identifiers'})));
                                }
                                const result = suggestionsList.filter(
                                    (x) => x.name.toLowerCase().indexOf(token.toLowerCase()) >= 0
                                );
                                let previousType = "";
                                return result.map((x) => {
                                    if (x.type !== previousType) {
                                        x.hasHeader = true;
                                        previousType = x.type;
                                    }
                                    return {
                                        name: x.name,
                                        type: x.type,
                                        hasHeader: x.hasHeader,
                                        output: `[${x.value}]`
                                    };
                                });
                            },

                            component: AutoCompleteItem as any,
                            output: (item, trigger) => (item as any).output
                        },
                    }}
              />
            default:
                break;
        }
    }
    else {
        switch (id) {
            case CONSTANTS.CALCULATION_STEP_FIELDS.NAME:
                const errorsList = (step.validationErrors || []).map(x => <li>{x.message}</li>);
                const errorsContent = errorsList.length > 0? <React.Fragment><b>Validation Errors</b><ul>{errorsList}</ul></React.Fragment> : null;

                const errorIndicator = errorsContent? <Popover  key={`pop${id}_${index}`}
                        data-class="basicSmallPopover"
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                            errorsContent
                        }
                    >
                        <Icon
                        name="status-warning"
                        size="normal"
                        variant="warning"
                        />
                    </Popover> : null;
                if (step.appliedTPEntry){
                    return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus} padded-inline-cell ${id}Cell`}>
                        <div className="greyBadge">{step.appliedTPEntry.name}</div>
                        <div className="stepErrorContainer">{errorIndicator}</div>
                    </div>;
                }
                else if (step.stepName === ""){
                    return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus} cell-text ${id}Cell`}>
                            <span>Click to enter step name or choose TP entry type</span>
                            <div className="stepErrorContainer">{errorIndicator}</div>
                        </div>;
                }
                else if (step.isSubstep){
                    return  <span className={step.isSubstep? "valueCellLeftPadded" : "valueCell"}>{step.stepName}</span>
                }
                else {
                    return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus} cell-text ${id}Cell`}>
                            <span>{value}</span>
                            <div className="stepErrorContainer">{errorIndicator}</div>
                        </div>;
                }
                break;
            case CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW:
                return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus}`}>
                        {step.expression == null || step.expression === ""? "Click to enter TP calculation formula" : step.expressionFormatted}
                        {editorsData.shouldShowValues && step.outputBreakdown? <div className="greyBadge">{step.outputBreakdown}</div> : null }
                    </div>;
            break;
            case CONSTANTS.CALCULATION_STEP_FIELDS.OUTPUT:
                const convertedValue = isNaN(value)? value : Number(value as string);
                return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus} cell-text ${id}Cell`}>{value}</div>
        }
    }

    return <div key={`${id}_${index}`} className={`save-status-${step.savingStatus} cell-text ${id}Cell`}>{value}</div>;
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

// TODO - Move to constants and resuse for data sources
const StepContextMenuActions = {
    EDIT: { text: "Edit", id: "Edit" },
    DUPLICATE: { text: "Duplicate", id: "Duplicate" },
    ADD_COMMENT: { text: "Add comment", id: "Add comment" },
    REMOVE: { text: "Remove", id: "Remove" },
    ADD_SUBSTEP: { text: "Add substep", id: "Add substep" },

    ADD_STEP_BEFORE: { text: "Add step before", id: "Add step before" },
    ADD_STEP_AFTER: { text: "Add step after", id: "Add step after" },
    ADD_SUBSTEP_BEFORE: { text: "Add substep before", id: "Add step before" },
    ADD_SUBSTEP_AFTER: { text: "Add substep after", id: "Add step after" },
    SET_UP_STANDARD_ALLOCATION: { text: "Set up Standard Allocation", id: "Set up Standard Allocation" },
    VIEW_STANDARD_ALLOCATION: { text: "View Standard Allocation", id: "View Standard Allocation" },
    DELETE_STANDARD_ALLOCATION: { text: "Delete Standard Allocation", id: "Delete Standard Allocation" },

}

const parentStepMenuItems = [
    StepContextMenuActions.EDIT,
    StepContextMenuActions.DUPLICATE,
    StepContextMenuActions.ADD_SUBSTEP,
    StepContextMenuActions.ADD_STEP_BEFORE,
    StepContextMenuActions.ADD_STEP_AFTER,
    StepContextMenuActions.ADD_COMMENT,
    StepContextMenuActions.REMOVE
];

const subStepMenuItems = [
    StepContextMenuActions.EDIT,
    StepContextMenuActions.DUPLICATE,
    StepContextMenuActions.ADD_SUBSTEP_BEFORE,
    StepContextMenuActions.ADD_SUBSTEP_AFTER,
    StepContextMenuActions.ADD_COMMENT,
    StepContextMenuActions.REMOVE
];

const nonEditableStepsMenuItems = [
    StepContextMenuActions.ADD_COMMENT
];

const SUCCESSFUL_COMMENT_ALERT = {
    message: "The comment was added successfully",
    type: "success",
    visible: true,
};




export default function CalculationStepsGrid() {
    const { calcBuilderState, calcBuilderDispatch} = React.useContext(CalculationBuilderContext);
    const { services, state, dispatch, userProfile } = React.useContext(StepsContext);
    const { showValues, steps, tpeEntries, stepBeingEdited, expandedState,
        removeStepModalPayload, commentsModalPayload, commentsHistory, stepSavedId,
        commentsNeedRefresh, tpeEntriesApplied, addCommentPayload, unsavedComments, builtInFunctions, showStandardAllocationDeleteConfirmModal
    } = state;
    const { calculation, selectedCalculationVersion, dataSourceRecords, calculationTemplateMetaData, viewMode } = calcBuilderState;
    const [gridInitialState, setGridInitialState] = useState({} as any);
    const calculationHasTemplate = calculationTemplateMetaData?.templateId != null;
    const [deleteStandardAllocationRequest, setDeleteStandardAllocationRequest] = useState(null as any);
    const [deleteStandardAllocationResult, deleteStandardAllocationLoading, deleteStandardAllocationError] = services.standardAllocationService.deleteStepStandardAllocation(deleteStandardAllocationRequest);

    useEffect(() => {
        // Saving the current expanded state before refreshing the data.
        // This prevents collapsing a parent step that user could had already expanded.
        setGridInitialState({expanded: expandedState});
    },[expandedState])

    useEffect(() => {
        if (deleteStandardAllocationResult == null) {
            return;
        }
        dispatch(ACTIONS.DELETE_STANDARD_ALLOCATION_RECORD);
        const currentStep = steps.find(x => x.stepId === state.stepIdForAction)
        services.messageService.showSuccessAutoDismissBanner(`Standard Allocation has been successfully deleted for step: ${currentStep?.stepName}.`);
        setDeleteStandardAllocationRequest(null);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteStandardAllocationResult])


    // Columns without a Cell property have rendering handled by the EditableCell renderer
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "visibleSequence",
            Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
            Cell: ({ row, rows, toggleRowExpanded }: { row: any, rows: any, toggleRowExpanded: any }) => {
                const step = row.original as CalculationStep;
                return <div className={`save-status-${row.original.savingStatus} calStepsSequenceCell textCell ${step.isSubstep? 'substepSequence' : ''}`}>
                        <span className={`sequenceCellValue ${row.original.subRows?.length > 0? "sequenceCellValueParent" : ""}`}>{row.original.visibleSequence}</span>
                        {row.canExpand ? (
                        <span className="subStepsExpander"
                            {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            })}
                        >

                            {row.isExpanded ? <Icon
                                name="treeview-collapse"
                                size="small"
                                variant="normal"
                                /> :
                                <Icon
                                name="treeview-expand"
                                size="small"
                                variant="normal"
                            />}
                        </span>
                        ) : null }
            </div>
        },
        },
        {
            accessor: CONSTANTS.CALCULATION_STEP_FIELDS.NAME,
            Header: (e: any) => <div className="cell-text-non-sortable">Step name</div>,
            // Cell: ({ row }: { row: any, rows: any, toggleRowExpanded: any }) => {
            //     const step = row.original as CalculationStep;
            //     return  <span className={step.isSubstep || (step.subRows?.length || 0) > 0? "valueCellLeftPadded" : "valueCell"}>{step.stepName}</span>
            // }
        },
        {
            accessor: CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW,
            Header: <div className="headerToggle">Calculation overview &nbsp;
                        <Popover
                            data-class="basicPopover"
                            className="calcOverviewPopover"
                            dismissButton={false}
                            position="right"
                            size="small"
                            triggerType="custom"
                            content="Click the expression to edit. Type '@' to display the autocomplete menu."
                        >
                            <Icon
                            name="status-info"
                            size="small"
                            variant="link"
                            />
                        </Popover>
                        <div className="toggleDiv">
                            <Toggle className="showDecimalsToggle"
                                    onChange={() => dispatch(ACTIONS.TOGGLE_SHOW_VALUES)}
                                    checked={showValues}><div className="cell-text-toggle">Show values</div>
                            </Toggle>
                        </div>
                    </div>
        },
        {
            accessor: `${CONSTANTS.CALCULATION_STEP_FIELDS.OUTPUT}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">Formula output</div>,
        },
        {
            accessor: "actions",
            Header: (e: any) => <div className="cell-text-non-sortable">Actions</div>,
            Cell: (e: any) => {
                const stepMenuItems = [...parentStepMenuItems]
                if (calculationHasTemplate) {
                    stepMenuItems.push(e.row.original.standardAllocation? StepContextMenuActions.VIEW_STANDARD_ALLOCATION:StepContextMenuActions.SET_UP_STANDARD_ALLOCATION);
                    if (e.row.original.standardAllocation) {
                        stepMenuItems.push(...[StepContextMenuActions.DELETE_STANDARD_ALLOCATION]);
                    }
                }
                return (
                <div className="rowActions formulaOutputCell">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    {viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.standardAllocation ?
                        <span className='greenTextWrapper'><Button iconName="folder" variant="icon" onClick={() => { openStandardAllocationModal(dispatch, e.row.original.stepId); }} /></span> : <span>&nbsp;</span>
                    }
                    {viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.hasComments ?
                        <Button iconName="contact" variant="icon"
                                onClick={() => dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload({step: e.row.original}))}/> :
                        <span>&nbsp;</span>
                    }
                    {e.row.original.isNew ? <span>&nbsp;</span> : <Popover data-class="basicSmallPopover" position="top" dismissButton={false} triggerType="custom" content={renderInfo(e.row.original.lastModifiedUser, e.row.original.lastModifiedDate)}>
                            <Link><StatusIndicator data-class="smallStatusIndicator" type="info" colorOverride="grey"/></Link>
                        </Popover>
                    }
                    {viewMode != CONSTANTS.VIEW_MODE.FROZEN &&
                        <ButtonDropdown className="rowContextMenu calcStepsContextMenu" onItemClick={(bde) => {
                            onStepMenuClicked(bde.detail, e.row, dispatch, services, calculation?.calculationNumber || '');
                        }}
                                        items={e.row.original.isEditable ? (e.row.original.isSubstep ? subStepMenuItems : stepMenuItems) : nonEditableStepsMenuItems}
                        >
                        </ButtonDropdown>
                    }

                </div>
                )
            }
        }
    ], [showValues, steps]);

    const [skipPageReset, setSkipPageReset] = React.useState(false);
    const [formattedRecords, setFormattedRecords] = React.useState([] as CalculationStep[]);
    const [standardAllocationIds, setStandardAllocationIds] = React.useState(new Set<string>());

    React.useEffect(() => {
        if (steps == null) {
            return;
        }
        steps.forEach(s => {
            s.visibleSequence = `${s.sequence}`;
            s.subRows?.forEach(sub => sub.visibleSequence = `${s.sequence}.${sub.sequence}`);
        })
        const flatSteps = ReadOnlyCalculationService.flattenSteps(steps).map(x => ({name: x.stepName, visibleSequence: x.visibleSequence}));
        const standardAllocationIds = new Set(dataSourceRecords.map(x => x.standardAllocation?.standardAllocationName || ''));
        steps.forEach(x => standardAllocationIds.add(x.standardAllocation?.standardAllocationName || ''));
        standardAllocationIds.delete('');
        const dataSourceDescriptions = dataSourceRecords.map(x => ({name: x.description}));
        setStandardAllocationIds(standardAllocationIds);
        setFormattedRecords(steps.map(x => {
            const parts = services.readOnlyCalculationService.breakExpressionIntoParts(x.expression, flatSteps, dataSourceDescriptions, standardAllocationIds);
            x.expressionTokens = parts;
            if (x.appliedTPEntry == null){
                const tpeEntry = tpeEntries.find(entry => entry.name === x.stepName);
                if (tpeEntry){
                    tpeEntriesApplied.add(tpeEntry.name);
                }
                x.appliedTPEntry = tpeEntry;
            }
            return {
                ...x,
                isEditable: viewMode == CONSTANTS.VIEW_MODE.EDITABLE,
                expression: x.expression,
                expressionFormatted: renderExpressionParts(parts),
                formulaOutputFormatted: isNaN(x.formulaOutput as any)? x.formulaOutput : services.formattingService.formatString(x.formulaOutput, true, 10),
                savingStatus: selectedCalculationVersion == CONSTANTS.STAGING_VERSION_NUMBER? SavingStatus.Unsaved : x.savingStatus,
                subRows: ArrayUtils.isNullOrEmpty(x.subRows)? undefined : x.subRows?.map(sub => {
                    const subParts = services.readOnlyCalculationService.breakExpressionIntoParts(sub.expression, flatSteps, dataSourceDescriptions, standardAllocationIds);
                    sub.expressionTokens = subParts;
                    return {
                        ...sub,
                        isEditable: viewMode == CONSTANTS.VIEW_MODE.EDITABLE,
                        expression: sub.expression,
                        expressionFormatted: renderExpressionParts(subParts),
                        formulaOutputFormatted: isNaN(sub.formulaOutput as any)? sub.formulaOutput : services.formattingService.formatString(sub.formulaOutput, true, 10),
                        savingStatus: selectedCalculationVersion == CONSTANTS.STAGING_VERSION_NUMBER? SavingStatus.Unsaved : sub.savingStatus,
                    }
                })
            };
        }));
    }, [steps, showValues, stepBeingEdited?.expression, dataSourceRecords, tpeEntries]);

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const onRecordUpdated = (rowIndex: number, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        if (columnId === CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW) {
            const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
            dispatch(ACTIONS.UPDATE_CURRENT_STEP.withPayload({
                field: columnId,
                value: value,
                extraValue: services.readOnlyCalculationService.breakExpressionIntoParts(
                    value,
                    flatSteps.map(x => ({name: x.stepName, visibleSequence: x.visibleSequence})),
                    dataSourceRecords.map(x => ({name: x.description})),
                    standardAllocationIds
                )
        })
            );
        }
        else {
            dispatch(ACTIONS.UPDATE_CURRENT_STEP.withPayload({field: columnId, value: value}));
        }
    }


    const onFormulaEditorCaretPositionChanged = (position: number) => {
        dispatch(ACTIONS.SET_EDITOR_CARET_POSITION.withPayload(position));
    }

    const onRowClicked = (e: any, step: CalculationStep, index:number) => {
        if (viewMode != CONSTANTS.VIEW_MODE.EDITABLE) {
            return;
        }
        // We don't do anything if user clicks on the formula output, error container tooltip or sequence cell
        if (
            !step.isEditable ||
            DomUtils.isDescendantOfClassName(e.target, 'formulaOutputCell') ||
            DomUtils.isDescendantOfClassName(e.target, 'calStepsSequenceCell') ||
            DomUtils.isDescendantOfClassName(e.target, 'stepErrorContainer')
        ){
            return;
        }
        if (step.savingStatus === SavingStatus.Unsaved) {
            dispatch(ACTIONS.SET_STEP_BEING_EDITED.withPayload(step));
        }
    }

    const handleAddCommentClicked = (request: CalcBuilderComment) => {
        if (commentsModalPayload?.step?.isNew){
            setCommentsModalMessage(SUCCESSFUL_COMMENT_ALERT);
            request.ownerIsUnsaved = true;
            request.stepId = commentsModalPayload.step.stepId;
            request.createUser = userProfile.user;
            request.calculationVersion = selectedCalculationVersion;
            dispatch(ACTIONS.ADD_STEP_COMMENT.withPayload(request));
        }
        else {
            dispatch(ACTIONS.SET_ADD_COMMENT_PAYLOAD.withPayload({
                stepId: String(commentsModalPayload?.step.stepId || ''),
                calculationNumber: calculation?.calculationNumber || '',
                calculationVersion: selectedCalculationVersion == null ? -1 : selectedCalculationVersion,
                comment: request.comment,
            }));
        }
    }

    const [commentsModalMessage, setCommentsModalMessage] = React.useState(undefined as TemporaryMessage | undefined);
    const [saveStepCommentsResult, isSavingStepComments, saveStepCommentsError] = services.calculationStepsService.saveStepComment(addCommentPayload);
    const [getStepCommentsResult, isLoadingStepComments, getStepCommentsError] = services.calculationStepsService.getStepComments(calculation?.calculationNumber, selectedCalculationVersion, commentsModalPayload?.step.stepId, commentsNeedRefresh);
    const [currentUnsavedCommentIndex, setCurrentUnsavedCommentIndex] = React.useState(-1);
    const [unsavedCommentRequest, setUnsavedCommentRequest] = React.useState(undefined as CalcBuilderComment | undefined);
    const [unsavedCommentResult, unsavedCommentLoading, unsavedCommentError] = services.calculationStepsService.saveStepComment(unsavedCommentRequest);

    useEffect(() => {
        if (commentsModalPayload == null){
            return;
        }
    }, [commentsModalPayload]);

    useEffect(() => {
        if (getStepCommentsResult == null){
            return;
        }
        dispatch(ACTIONS.SET_COMMENTS_HISTORY.withPayload(getStepCommentsResult));
    }, [getStepCommentsResult]);

    useEffect(() => {
        if (saveStepCommentsResult == null){
            return;
        }

        setCommentsModalMessage(SUCCESSFUL_COMMENT_ALERT);
        dispatch(ACTIONS.ADD_STEP_COMMENT.withPayload(saveStepCommentsResult));
    }, [saveStepCommentsResult]);

    useEffect(() => {
        if (currentUnsavedCommentIndex < 0 || currentUnsavedCommentIndex >= unsavedComments.length){
            return;
        }
        setUnsavedCommentRequest(unsavedComments[currentUnsavedCommentIndex]);
    }, [currentUnsavedCommentIndex])

    useEffect(() => {
        if (stepSavedId == null){
            return;
        }
        if (!ArrayUtils.isNullOrEmpty(unsavedComments)){
            setCurrentUnsavedCommentIndex(0);
        }
    }, [stepSavedId])

    useEffect(() => {
        if (unsavedCommentResult == null){
            return;
        }
        const nextIndex = currentUnsavedCommentIndex+1;
        if (nextIndex >= unsavedComments.length){
            setCurrentUnsavedCommentIndex(-1);
            dispatch(ACTIONS.CLEAR_UNSAVED_COMMENTS);
        }
        else {
            setCurrentUnsavedCommentIndex(nextIndex);
        }
    }, [unsavedCommentResult]);

    const CLI_HAS_BEAT_QUALIFIER = CONSTANTS.BEAT_QUALIFIERS.includes(calculation?.beat || "");

    const postDeleteStandardAllocation = () => {
        dispatch(ACTIONS.HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL);
        setDeleteStandardAllocationRequest({
            templateId: calculationTemplateMetaData?.templateId,
            stepId: state.stepIdForAction
        });
    }

    return <div className="dataSourceTableContainer">
        { viewMode == CONSTANTS.VIEW_MODE.EDITABLE && <FormulaExpressionToolbar builtInFunctions={builtInFunctions} dispatch={dispatch} /> }
        { calculationTemplateMetaData?.modelName != null && <div className="tableGroupHeader"><span>TP Model - {calculationTemplateMetaData?.modelName}</span></div> }
        <TPEReactTable {...{
            data: formattedRecords,
            columnDefinitions,
            className: "calculationSteps",
            sortable: false,
            onRowClicked: onRowClicked,
            initialState: gridInitialState,
            onExpandedChanged: (e) => dispatch(ACTIONS.SET_EXPANDED_STATE.withPayload(e)),
            editableOptions: {
                defaultColumn: defaultCellEditor,
                onRowUpdated: onRecordUpdated,
                skipPageReset,
                editorsData: {
                    tpeEntries: tpeEntries.filter(x =>
                        x.beatQualifier === CONSTANTS.TP_ENTRY_BEAT_QUALIFIER_TYPES.ALL ||
                        x.beatQualifier === (CLI_HAS_BEAT_QUALIFIER? CONSTANTS.TP_ENTRY_BEAT_QUALIFIER_TYPES.BEAT : CONSTANTS.TP_ENTRY_BEAT_QUALIFIER_TYPES.NON_BEAT)
                    ).map((x : CalculationTPEEntry) => ({ value: x.name })),
                    shouldShowValues: showValues,
                    suggestionsList: dataSourceRecords.map(x => ({name: x.description, type: "Data sources", value: x.description})),
                    stepsList: ReadOnlyCalculationService.flattenStepsSorted(formattedRecords, (a,b) => parseFloat(a.visibleSequence || '0') - parseFloat(b.visibleSequence || '0')),
                    onTextAreaCaretPositionChanged: onFormulaEditorCaretPositionChanged,
                    tpeEntriesApplied: tpeEntriesApplied,
                    standardAllocationIds: standardAllocationIds,
                }
            }
        }} />

        <CommentsModal
            header={`Step ${commentsModalPayload?.step.visibleSequence} comments`}
            visible={commentsModalPayload != null}
            onCancel={() => dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload(null))}
            onAddComment={handleAddCommentClicked}
            calcNumber={calculation?.calculationNumber as string}
            commentsHistory={commentsHistory}
            modalMessage={commentsModalMessage}
            isSaving={isLoadingStepComments || isSavingStepComments}
        />

        <TPEBasicModal
            className="standardAllocationDeleteConfirmModal"
            visible={showStandardAllocationDeleteConfirmModal}
            action={ACTION_TYPE.YES_NO}
            title="Delete standard allocation"
            onCancel={() => dispatch(ACTIONS.HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL)}
            onConfirm={postDeleteStandardAllocation}
        >
            Are you sure you want to delete this standard allocation?
        </TPEBasicModal>

        <TPEBasicModal
            className="calcStepDeleteConfirmModal"
            visible={removeStepModalPayload != null}
            action={ACTION_TYPE.YES_NO}
            title="Remove Step Confirmation"
            onCancel={() => dispatch(ACTIONS.SET_REMOVE_STEP_MODAL_PAYLOAD.withPayload(null))}
            onConfirm={() => onDeleteStepConfirmed(dispatch, removeStepModalPayload)}
        >
            <span>Are you sure you want to remove step #{removeStepModalPayload?.step.visibleSequence} {removeStepModalPayload?.step.name}{removeStepModalPayload?.step.subRows? ` and ${removeStepModalPayload?.step.subRows.length} substep(s)` : ''}?</span>
        </TPEBasicModal>
</div>
}



function onStepMenuClicked(detail: ButtonDropdownProps.ItemClickDetails, row: Row, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, calculationNumber: string): void {
    const step = row.original as CalculationStep;
    dispatch(ACTIONS.SET_STEP_ID_FOR_ACTION.withPayload(step.stepId));
    // Doing this since calculationNumber is missing on steps coming from API
    step.calculationNumber = calculationNumber;
    switch(detail.id){
        case StepContextMenuActions.EDIT.id:
            dispatch(ACTIONS.SET_STEP_BEING_EDITED.withPayload(step));
        break;
        case StepContextMenuActions.DUPLICATE.id:
            dispatch(ACTIONS.DUPLICATE_STEP.withPayload({ index: row.index + 1, originalStep: step }));
        break;
        case StepContextMenuActions.ADD_STEP_BEFORE.id: {
            const newStep = services.calculationStepsService.createNewCalculationStep(step.sequence - 1, calculationNumber, step.isSubstep || false);
            if (step.isSubstep){
                newStep.parentId = step.parentId;
            }
            dispatch(ACTIONS.ADD_STEP_BEFORE.withPayload({ index: row.index, newStep }));
            break;
        }
        case StepContextMenuActions.ADD_STEP_AFTER.id: {
            const newStep = services.calculationStepsService.createNewCalculationStep(step.sequence, calculationNumber, step.isSubstep || false);
            if (step.isSubstep){
                newStep.parentId = step.parentId;
            }
            dispatch(ACTIONS.ADD_STEP_AFTER.withPayload({ index: row.index, newStep }))
            break;
        }
        case StepContextMenuActions.ADD_SUBSTEP.id:
            const subStepSequence = (step.subRows != null? step.subRows.length : 0) + 1;
            const newSubstep = services.calculationStepsService.createNewCalculationStep(subStepSequence, calculationNumber, true);

            dispatch(ACTIONS.ADD_SUBSTEP.withPayload({parentStep: step, newSubstep}));
        break;
        case StepContextMenuActions.REMOVE.id:
            dispatch(ACTIONS.SET_REMOVE_STEP_MODAL_PAYLOAD.withPayload({ index: row.index, step }))
        break;
        case StepContextMenuActions.ADD_COMMENT.id:
            dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload({ step }))
        break;
        case StepContextMenuActions.SET_UP_STANDARD_ALLOCATION.id:
            dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(true))
            break;
        case StepContextMenuActions.VIEW_STANDARD_ALLOCATION.id:
            dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(true))
            break;
        case StepContextMenuActions.DELETE_STANDARD_ALLOCATION.id: {
            dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL);
            break;
        }

    }
}


function onDeleteStepConfirmed(dispatch: React.Dispatch<TPEAction>, payload: any) {
    dispatch(ACTIONS.REMOVE_STEP.withPayload(payload))
}


