import React, {useEffect} from "react";
import {FormField, Grid, Select, SelectProps} from "@amzn/awsui-components-react/polaris";
import CONSTANTS from "src/utils/constants";
import ServiceCollection from "src/services/ServiceCollection";
import {accountingDaysList} from "src/models/reports/GetAccountingDayList";

type ComponentProps = {
    services: ServiceCollection,
    selectedCompanyCode: SelectProps.Option | null,
    selectedPeriodOption: SelectProps.Option | null,
    selectedAccountingDayOption: SelectProps.Option | null,
    onCompanyCodeSelected: (option: SelectProps.Option | null) => void,
    onPeriodSelected: (option: SelectProps.Option | null) => void,
    onAccountingDaySelected: (option: SelectProps.Option | null) => void
}

export default function TPTaxLefReportFilterSelector(props: ComponentProps) {
    const {
        services,
        selectedCompanyCode,
        selectedPeriodOption,
        selectedAccountingDayOption,
        onCompanyCodeSelected,
        onPeriodSelected,
        onAccountingDaySelected
    } = props;

    const [companyDataResult, companyDataLoading, companyDataError] = services.reportsService.getCOADataBySegment(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API)
    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = services.reportsService.getReportingPeriods();

    useEffect(() => {
        if (!accountingPeriodError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(accountingPeriodError);
    }, [accountingPeriodError]);

    useEffect(() => {
        if (!companyDataError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(companyDataError);
    }, [companyDataError]);


    return (
        <Grid
            gridDefinition={[{colspan: 6}, {colspan: 3}, {colspan: 3}]}
        >
            <FormField label="Legal Entity">
                <Select
                    statusType={companyDataLoading ? "loading" : "finished"}
                    loadingText="Fetching Legal Entities Data"
                    selectedOption={selectedCompanyCode ? {
                        label: selectedCompanyCode.label,
                        value: selectedCompanyCode.value
                    } : null}
                    onChange={({detail}) =>
                        onCompanyCodeSelected(detail.selectedOption)
                    }
                    options={
                        companyDataResult == null
                            ? []
                            : companyDataResult.map(companyData => ({
                                label: `${companyData.codeId} - ${companyData.codeName}`,
                                value: companyData.codeId
                            }))
                    }
                    placeholder="Select Option"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <FormField label="Period">
                <Select
                    statusType={accountingPeriodLoading ? "loading" : "finished"}
                    loadingText="Fetching Accounting Periods"
                    selectedOption={selectedPeriodOption ? {
                        label: selectedPeriodOption.label,
                        value: selectedPeriodOption.value
                    } : null}
                    onChange={({detail}) =>
                        onPeriodSelected(detail.selectedOption)
                    }
                    options={
                        accountingPeriodResult == null
                            ? []
                            : accountingPeriodResult.ReportingPeriodList.map(
                                (accountingPeriodNameValue) => ({
                                    label: accountingPeriodNameValue,
                                    value: accountingPeriodNameValue,
                                })
                            )
                    }
                    placeholder="Select Option"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <FormField label="Accounting Day">
                <Select
                    selectedOption={selectedAccountingDayOption ? {
                        label: selectedAccountingDayOption.label,
                        value: selectedAccountingDayOption.value
                    } : null}
                    options={accountingDaysList}
                    onChange={({detail}) => {
                        onAccountingDaySelected(detail.selectedOption);
                    }}
                    placeholder="Select Option"
                />
            </FormField>
        </Grid>
    );
}