import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {
    Box,
    Button,
    CollectionPreferences,
    PropertyFilterProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {
    Container, FormField,
    Grid,
    Header,
    Select,
    SelectProps
} from "@amzn/awsui-components-react/polaris";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TPTaxLefReportFilterSelector from "src/components/reports/lefReport/TPTaxLefReportFilterSelector";

export default function TPTaxLefReportView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [selectedCompanyCodeOption, setSelectedCompanyCodeOption] = React.useState(null as SelectProps.Option | null);
    const [selectedPeriodOption, setSelectedPeriodOption] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [selectedFunctionalSegmentOption, setSelectedFunctionalSegmentOption] = React.useState(null as SelectProps.Option | null);

    const [query, setQuery] = React.useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });

    return (
        <SpaceBetween direction="vertical" size="xl">
            <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                <TPTaxLefReportFilterSelector
                    services={services}
                    selectedCompanyCode={selectedCompanyCodeOption}
                    selectedPeriodOption={selectedPeriodOption}
                    selectedAccountingDayOption={selectedAccountingDayOption}
                    onCompanyCodeSelected={(companyCodeOption) => setSelectedCompanyCodeOption({
                        label: companyCodeOption?.label,
                        value: companyCodeOption?.value
                    })}
                    onPeriodSelected={(periodOption) => setSelectedPeriodOption({
                        label: periodOption?.label,
                        value: periodOption?.value
                    })}
                    onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                        label: accountingDayOption?.label,
                        value: accountingDayOption?.value
                    })}
                />
                <Box padding={{top: "xl"}}>
                    <SpaceBetween direction="horizontal" size="l">
                        <Button variant="primary"
                                disabled={!(!!selectedCompanyCodeOption && !!selectedPeriodOption && !!selectedAccountingDayOption)}>
                            View Report
                        </Button>
                    </SpaceBetween>
                </Box>
                <Box/>
            </Grid>
            <Container header={
                <>
                    <Header
                        variant={"h2"}
                        actions={<Button>Download Full Report</Button>}
                    >
                        Report Details
                    </Header>

                    <Grid gridDefinition={[
                        {colspan: 4},
                        {colspan: 4},
                        {colspan: 4}
                    ]}>
                        <FormField label="&nbsp;">
                            <PropertyFilter
                                query={query}
                                onChange={({detail}) => setQuery(detail)}
                                expandToViewport
                                filteringAriaLabel="Search CLIs"
                                filteringPlaceholder="Search CLIs"
                                filteringOptions={[
                                    {
                                        propertyKey: "Calculation Number",
                                        value: "00008604.0_02_M2-3M"
                                    }, {
                                        propertyKey: "Calculation Number",
                                        value: "00008602.0_01_M2-3M"
                                    }, {
                                        propertyKey: "Calculation Number",
                                        value: "00008606.0_03_M2-3M"
                                    }, {
                                        propertyKey: "Calculation Number",
                                        value: "00008608.0_04_M2-3M"
                                    }, {
                                        propertyKey: "Calculation Number",
                                        value: "00008610.0_05_M2-3M"
                                    }, {
                                        propertyKey: "Calculation Number",
                                        value: "00008612.0_06_M2-3M"
                                    }, {
                                        propertyKey: "Second Party Legal Entity",
                                        value: "3M"
                                    }, {
                                        propertyKey: "Second Party Legal Entity",
                                        value: "1M"
                                    }, {
                                        propertyKey: "Second Party Legal Entity",
                                        value: "2M"
                                    }, {
                                        propertyKey: "Second Party Legal Entity",
                                        value: "4M"
                                    }, {
                                        propertyKey: "TP Policy",
                                        value: "8.0"
                                    }, {
                                        propertyKey: "TP Policy",
                                        value: "16.0"
                                    }, {
                                        propertyKey: "TP Policy",
                                        value: "18.0"
                                    }
                                ]}
                                filteringProperties={[
                                    {
                                        key: "Calculation Number",
                                        operators: ["="],
                                        propertyLabel: "Calculation Number",
                                        groupValuesLabel: "Calculation Number values"
                                    },
                                    {
                                        key: "TP Policy",
                                        operators: ["="],
                                        propertyLabel: "TP Policy",
                                        groupValuesLabel: "TP Policy values"
                                    },
                                    {
                                        key: "Second Party Legal Entity ",
                                        operators: ["="],
                                        propertyLabel: "Second Party Legal Entity",
                                        groupValuesLabel: "Second Party Legal Entity"
                                    },
                                    {
                                        key: "Second Party Oracle ID",
                                        operators: ["="],
                                        propertyLabel: "Second Party Oracle ID",
                                        groupValuesLabel: "Second Party Oracle ID values"
                                    }
                                ]}
                                i18nStrings={{
                                    clearFiltersText: "Clear filters",
                                    removeTokenButtonAriaLabel: () => "Remove token",
                                    groupValuesText: "Values",
                                    groupPropertiesText: "Properties",
                                    operatorsText: "Operators",
                                    operationAndText: "and",
                                    operationOrText: "or",
                                    editTokenHeader: "Edit filter",
                                    propertyText: "Property",
                                    operatorText: "Operator",
                                    valueText: "Value",
                                    cancelActionText: "Cancel",
                                    applyActionText: "Apply",
                                    allPropertiesLabel: "All properties",
                                }}
                            />
                        </FormField>
                        <FormField label="Functional Segment">
                            <Select selectedOption={null}/>
                        </FormField>
                        <Box float={"right"} padding={{top: "xl"}}>
                            <SpaceBetween size={"xs"} direction={"horizontal"}>
                                <Pagination currentPageIndex={1} pagesCount={20}/>
                                <CollectionPreferences/>
                            </SpaceBetween>
                        </Box>
                    </Grid>
                </>}>
            </Container>
        </SpaceBetween>
    );
}
