import React, { useMemo, useEffect, useState } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import YtdPeriodBalance from 'src/models/periodCharges/YtdPeriodBalance';
import { Box, SpaceBetween, Button, Pagination, Header, StatusIndicator, FormField, Select, Grid } from '@amzn/awsui-components-react';
import PeriodChargesLOVsResponse from 'src/models/periodCharges/PeriodChargesLOVsResponse';

const columnDefinitions = [
        {
          id: "calculationNumber",
          header: "CLI",
          cell: (e: YtdPeriodBalance) => e.calculationNumber,
          width: 300,
          minWidth: 180,
          isRowHeader: true
        },
        {
          id: "currency",
          header: "Currency",
          cell: (e: YtdPeriodBalance) => e.currency,
          width: 110,
          minWidth: 110,
        },
        {
          id: "beginningBalance",
          header: "YTD beginning balance",
          cell: (e: YtdPeriodBalance) => e.beginningBalance + "",
          width: 200,
          minWidth: 150
        },
        {
          id: "month",
          header: "Month",
          cell: (e: YtdPeriodBalance) => e.month,
          width: 100,
          minWidth: 60
        },
        {
          id: "year",
          header: "Year",
          cell: (e: YtdPeriodBalance) => e.year + "",
          width: 100,
          minWidth: 60
        },
        {
          id: "updatedOn",
          header: "Updated on",
          cell: (e: YtdPeriodBalance) => e.updatedOn?.toDateString() || "-",
          width: 200,
          minWidth: 170
        },
        {
          id: "approvalStatus",
          header: "Approval status",
          cell: (e: YtdPeriodBalance) => renderApprovalStatus(e.approvalStatus),
          width: 200,
          minWidth: 170
        },
        {
          id: "approvedOn",
          header: "Approved on",
          cell: (e: YtdPeriodBalance) => e.approvedOn?.toDateString() || "-",
          width: 120,
          minWidth: 120
        }
      ];

export default function YtdBalancesGrid(props: {
        data:YtdPeriodBalance[],
        filterLOVs: PeriodChargesLOVsResponse | null,
        onFilterChanged?: (selectedFilters:any) => void,
        onExportAllCLIs?: () => void,
        onApproveBalance?: () => void,
    }) {
    const {data, filterLOVs, onFilterChanged, onApproveBalance, onExportAllCLIs} = props;
    const [
        selectedYtdBalances,
        setSelectedYtdBalances
      ] = React.useState([] as any[]);
    const [
        selectedFilterAssignee,
        setSelectedFilterAssignee
    ] = React.useState({ value: "All" } as any);

    const [
        selectedStatusFilter,
        setSelectedStatusFilter
    ] = React.useState({ value: "All" } as any);
    
    const [
        selectedCLI,
        setSelectedCLIFilter
    ] = React.useState(null as any);

    const [
        selectedYearFilter,
        setSelectedYearFilter
    ] = React.useState({ value: "All" } as any);

    useEffect(() => {
        if (filterLOVs == null){
            return;
        }
        filterLOVs.assignees.unshift("All");
        filterLOVs.years.unshift("All");
        filterLOVs.statuses.unshift("All");
        filterLOVs.clis.unshift("All");
    }, [filterLOVs])

    useEffect(() => {
        if (onFilterChanged == null){
            return;
        }
        onFilterChanged({
            selectedCLI: selectedCLI?.value, 
            selectedAssignee: selectedFilterAssignee?.value, 
            selectedStatus: selectedStatusFilter?.value, 
            selectedYear: selectedYearFilter?.value
        })
    }, [selectedCLI, selectedFilterAssignee, selectedStatusFilter, selectedYearFilter])

    const onExportAllCLIsClicked = () => {
        if (onExportAllCLIs != null){
            onExportAllCLIs();
        }
    }
    
    const onApproveBalanceClicked = () => {
        if (onApproveBalance != null){
            onApproveBalance();
        }
    }

    
    
    const renderTableFilters = function (){
        return <Grid
        gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 4 }, { colspan: 2 }]}
      >
            <FormField label="Year">
                <Select selectedOption={selectedYearFilter}
                      onChange={({ detail }) =>
                        setSelectedYearFilter(detail.selectedOption)
                      }
                    options={filterLOVs?.years.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField>
            <FormField label="Assignee">
                <Select selectedOption={selectedFilterAssignee} filteringType="auto"
                    onChange={({ detail }) =>
                        setSelectedFilterAssignee(detail.selectedOption)
                    }
                    options={filterLOVs?.assignees.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField>
            <FormField label={<span>&nbsp;</span>}>
                <Select 
                        selectedOption={selectedCLI == null ? null : { label: selectedCLI.value, value: selectedCLI.value }}
                        options={filterLOVs?.clis.map(x => ({label: x, value: x}))}
                        onChange={({ detail }) => {
                            setSelectedCLIFilter(detail.selectedOption);
                        }}
                        loadingText="Loading calculation lines"
                        placeholder="Find specific CLI"
                        filteringType="auto"
                />
            </FormField>
            <FormField label="Status">
                <Select selectedOption={selectedStatusFilter}
                    onChange={({ detail }) =>
                        setSelectedStatusFilter(detail.selectedOption)
                    }
                    options={filterLOVs?.statuses.map(x => ({label: x, value: x}))}
                ></Select>
            </FormField>
        </Grid>;
    }
    
    return <Table 
        renderAriaLive={({
            firstIndex,
            lastIndex,
            totalItemsCount
        }) =>
            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
        }
        columnDefinitions={columnDefinitions}
        items={data}
        loadingText="Loading resources"
        resizableColumns
        empty={
        <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
        >
            <SpaceBetween size="m">
            <b>No resources</b>
            </SpaceBetween>
        </Box>
        }
        header={
        <Header 
            counter={
                selectedYtdBalances.length
                ? `(${selectedYtdBalances.length}/${data.length})`
                : `(${data.length})`
            }
            actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button onClick={onExportAllCLIsClicked}>Export all CLIs</Button>
                  <Button variant="primary" onClick={onApproveBalanceClicked}>
                    Approve balance
                  </Button>
                </SpaceBetween>
            }
        >YTD Prior Period Balance</Header>
        }
        pagination={
        <Pagination currentPageIndex={1} pagesCount={1} />
        }
        selectionType="multi"
        selectedItems={selectedYtdBalances}
        onSelectionChange={({detail}) => setSelectedYtdBalances(detail.selectedItems)}
        filter={renderTableFilters()}
        
    ></Table>
}

function renderApprovalStatus(approvalStatus: string) {

    switch(approvalStatus){
        case "Pending approval":
            return <StatusIndicator type="pending">{approvalStatus}</StatusIndicator>;
        case "Approved":
            return <StatusIndicator type='success'>{approvalStatus}</StatusIndicator>;
        default:
            return approvalStatus;
    }
}

