import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './CalculationBuilder.scss';
import StepsWizardBar from '../shared/StepsWizardBar'
import { CalculationBuilderState, getInitialICRSState, getInitialState, initialState } from 'src/services/calculation-builder/CalculationBuilderState';
import { calculationBuilderReducer, ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import { UserProfile } from 'src/models/users/UserProfile';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import TPEAction from 'src/models/common/TPEAction';
import {  Box,
          Button,
          SpaceBetween } from '@amzn/awsui-components-react';
import TPAgreementCLIDetails from './TPAgreementCLIDetails';
import DataSourcesContainer from './data-sources/DataSourcesContainer';
import StepsContainer from './calculation-steps/StepsContainer'
import JESettingsContainer from './je-settings/JESettingsContainer';
import CalculationBuilderSelectAgreement from './CalculationBuilder_SelectAgreement';
import CalculationBuilderSelectICRSRecord from './CalculationBuilder_SelectICRSRecord';
import { WizardStep } from 'src/models/common/WizardStep';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import { GlobalAppContext } from '../App';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { CLIDetailsResult } from "src/models/calculations/CLIDetailsResult";
import { AgreementVersionItem } from "src/models/agreements/AgreementVersionsResult";
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import ArrayUtils from 'src/utils/arrayUtils';
import CONSTANTS from 'src/utils/constants';
import TemplatesContainer from './calculation-templates/TemplatesContainer';
import WizardStepContentWrapper from './WizardStepContentWrapper';
import { TPLOV } from 'src/models/common/TPLOV';
import { CalculationStatusBar } from './CalculationStatusBar';
import ReviewJournalEntriesContainer from './review/ReviewJournalEntriesContainer';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import CalculationBuilderTemplateDownloader from './calculation-upload-template/CalculationBuilderTemplateDownloader'
import StringUtils from 'src/utils/stringUtils';
import ICRegistration from "src/models/icrs/ICRegistration";
import CalculationSource from "src/models/common/CalculationSource";
import ICRSRegistrationDetails from "./ICRSRegistrationDetails";
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import { ATPViewRestrictor } from '../shared/ATPViewRestrictor';
import TPEErrorWatcher from '../shared/TPEErrorWatcher';


export type ContextType = {
    calcBuilderState: CalculationBuilderState,
    calcBuilderDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    userProfile: UserProfile,
}

const CalculationBuilderProvider = (props: any) => {
    const { state, dispatch, services, userProfile, children } = props;
    const providerValue = React.useMemo(() => ({
        calcBuilderState: state, calcBuilderDispatch: dispatch, services, userProfile
    }), [state, dispatch]);
    return (
        <CalculationBuilderContext.Provider value={providerValue}>
            {children}
        </CalculationBuilderContext.Provider>
    );
}
export const CalculationBuilderContext = React.createContext(null as unknown as ContextType);

export default function CalculationBuilderView(props: { services: ServiceCollection, isICRSRecord: boolean }) {
    const { pathname } = useLocation();
    const { services, isICRSRecord } = props;
    const [state, dispatch] = useReducerWithLogger(calculationBuilderReducer, initialState, isICRSRecord? getInitialICRSState : getInitialState);
    const { wizardSteps, 
            selectedWizardStep,
            newCalculationNumber,
            calculationMetaDataNeedsRefresh,
            calculationVersionsNeedRefresh,
            cliDetailsNeedRefresh,
            selectedCalculationVersion,
            calculationTemplateMetaData,
            isEditingTemplate,
            linkedDataSources,
            calculationConfigurationState,
            generatingReviewJournalsStatus,
            calculationExecution,
            reviewJournals,
            executionPeriod,
            executionPeriodWasTouched,
            calculation,
            reviewJournalsNeedRefresh,
            dataSourceRecords
         } = state;
    const { canEdit } = getPermissions(AppModules.CALCULATION_BUILDER);

    const ENCODED_PARAM_NEW = 'new';
    
    const { encodedCliRecordId } = useParams<{ encodedCliRecordId: string }>();
    const [encodedParam, setEncodedParam] = React.useState(encodedCliRecordId);
    const [cliRecordId, setCliRecordId] = React.useState('');
    const [icrsRecordId, setIcrsRecordId] = React.useState('');
    const [loadTime, setLoadTime] = useState('');
    const [cliDetails, setCLIDetails] = useState(new CLIDetailsResult().cli);
    const [icrsDetails, setICRSDetails] = useState({} as ICRegistration);
    const [calculationSource, setCalculationSource] = useState(new CalculationSource());
    const [isValidCLI, setIsValidCLI] = useState(true);

    const [companyCodes, loadingCompanies, errorCompanies] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API);
    const [glAccounts, loadingGlAccounts, errorGlAccounts] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.WEB_API);
    const [costCenters, loadingCostCenters, errorCostCenters] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.WEB_API);
    const [productLines, loadingProductLines, errorProductLines] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.WEB_API);
    const [locations, loadingLocations, errorLocations] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.WEB_API);
    const [channels, loadingChannels, errorChannels] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.WEB_API);
    const [projects, loadingProjects, errorProjects] = services.dataSourcesService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.WEB_API);
    
    const [dataSourceTypes, currencyCodes, periods, currencyConversionTypes, loadingCheck, dataSourceLOVsError] = services.dataSourcesService.getDataSourceLOVs(loadTime);

    const [newCalcResult, newCalcLoading, newCalcError] = services.calculationsService.createCalculation(newCalculationNumber);
    const [cliDetailsResult, loading, cliDetailsError] = services.calculationsService.getCLIDetails(cliRecordId, cliDetailsNeedRefresh);
    const [icrsDetailsResult, loadingICRSDetails, icrsDetailsError] = services.icrsService.getICRegistrationDetails(icrsRecordId, cliDetailsNeedRefresh);
    const [linkedCLIsResult, loadinglinkedCLIs, linkedCLIsError] = services.calculationBuilderService.getLinkedCLIs(calculationTemplateMetaData?.templateId);
    const [agreementVersionsResult, loadingVersions, errorVersions] = services.agreementsService.getAgreementVersions(cliDetails?.agreementRecordId);
    const [calcVersionsResult, calcVersionsLoading, calcVersionsError] = services.calculationsService.getCalculationVersions(calculationSource.sourceKeyNumber, calculationVersionsNeedRefresh);
    const [calcMetadataResult, calcMetadataLoading, calcMetadataError] = services.calculationsService.getCalculationMetadata(calculationSource.sourceKeyNumber, selectedCalculationVersion as unknown as number, calculationMetaDataNeedsRefresh);
    const [versions, setVersions] = useState([] as AgreementVersionItem[]);
    const { globalDispatch, userProfile } = React.useContext(GlobalAppContext);
    const SELECT_AGREEMENT_WIZARD_STEP = wizardSteps[0];
    const DATA_SOURCES_WIZARD_STEP = wizardSteps[1];
    const CALC_STEPS_WIZARD_STEP = wizardSteps[2];
    const JE_SETTINGS_WIZARD_STEP = wizardSteps[3];
    const JE_REVIEW_WIZARD_STEP = wizardSteps[4];
    const [showBothSteps2And3, setShowBothSteps2And3] = useState(false);

    const [journalEntriesResult, reversalJournalEntriesResult, journalEntriesLoading, journalEntriesError] = services.journalsService.getJournalEntries(calculationExecution?.calculationExecutionId);

    // Pulling review journals here since the review journals component loads later and the calc builder, needs to know if there are review journals
    // So that it can enable calc builder steps accordingly
    const [reviewJournalsResult, reviewJournalsLoading, journalsError] = services.journalsService.getJournals(reviewJournalsNeedRefresh, CONSTANTS.JOURNAL_LINE_TYPES.REVIEW, calculation?.calculationNumber, selectedCalculationVersion);
    
    useEffect(() => {
        dispatch(ACTIONS.RESET_VIEW_MODE);
    }, [canEdit])

    useEffect(() => {
        if (!reviewJournalsNeedRefresh) {
            return;
        }
        dispatch(ACTIONS.SET_REVIEW_JOURNALS.withPayload(reviewJournalsResult))
    }, [reviewJournalsResult])

    useEffect(() => {
        dispatch(ACTIONS.SET_REFRESH_REVIEW_JOURNALS_FLAG.withPayload(executionPeriod == null));
    }, [executionPeriod])

    useEffect(() => {
        if (icrsDetailsResult == null){
            return;
        }
        setICRSDetails(icrsDetailsResult);
        setCalculationSource(CalculationSource.fromICRS(icrsDetailsResult))
        dispatch(ACTIONS.SET_CALCULATION.withPayload(icrsDetailsResult));        
    }, [icrsDetailsResult])

    useEffect(() => {
        setLoadTime(new Date().toDateString());
        services.messageService.setup(dispatch,ACTIONS.SET_TEMPORARY_MESSAGE);   
    }, [])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(dataSourceTypes) || ArrayUtils.isNullOrEmpty(currencyCodes)
            || ArrayUtils.isNullOrEmpty(periods) || ArrayUtils.isNullOrEmpty(currencyConversionTypes)) {
            return;
        }
        dispatch(ACTIONS.SET_TP_LOV.withPayload({
            dataSourceTypes, currencyCodes, periods, currencyConversionTypes
        } as TPLOV))
    }, [dataSourceTypes, currencyCodes, periods, currencyConversionTypes]);
    
   useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(companyCodes)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, lov: companyCodes }));
    }, [companyCodes]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(glAccounts)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI, lov: glAccounts }));
    }, [glAccounts]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(costCenters)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.UI, lov: costCenters }));
    }, [costCenters]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(productLines)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.UI, lov: productLines }));
    }, [productLines]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(locations)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.UI, lov: locations }));
    }, [locations]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(channels)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.UI, lov: channels }));
    }, [channels]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(projects)) {
            return;
        }
        dispatch(ACTIONS.SET_SEVEN_SEGMENT_LOV.withPayload({ segment: CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.UI, lov: projects }));
    }, [projects]);
    
    useEffect(() => {
        if (calcMetadataResult == null){
            return;
        }
        dispatch(ACTIONS.SET_TEMPLATE_METADATA.withPayload(calcMetadataResult));
        dispatch(ACTIONS.SET_CALCULATION_CONFIGURATION_STATE.withPayload(calcMetadataResult.calculationBuilderState || CONSTANTS.CALCULATION_CONFIGURATION_STATE.DATASOURCES_STEPS));
        if (isICRSRecord && icrsDetails && calculation){
            dispatch(ACTIONS.SET_CALCULATION.withPayload({...calculation,...calcMetadataResult}))
            if (encodedParam == ENCODED_PARAM_NEW || calcMetadataResult.calculationStatus.toLowerCase() == CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase()) {
                globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb( pathname, 
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_BREADCRUMB.replace('cli', icrsDetails.registrationNumber),
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_TITLE,
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_DESCRIPTION
                                                                                                       )));
            } else {
                globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb( pathname,
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_BREADCRUMB.replace('cli', icrsDetails.registrationNumber),
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_TITLE,
                                                                                                        CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_DESCRIPTION
                                                                                                    )));
            }
        }
    }, [calcMetadataResult, icrsDetails])
    
    useEffect(() => {
        dispatch(ACTIONS.SET_LINKED_CLIS.withPayload(linkedCLIsResult));
    }, [linkedCLIsResult])

    useEffect(() => {
        if (encodedParam == null || encodedParam === '') {
            return;
        }
        if (encodedParam === ENCODED_PARAM_NEW) {
            globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb( pathname, 
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_TITLE,
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_TITLE,
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_DESCRIPTION
                                                                                                )));
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(SELECT_AGREEMENT_WIZARD_STEP));
            dispatch(ACTIONS.SET_REFRESH_CLI_DETAILS_FLAG.withPayload(false));
            return;
        }
        try {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(DATA_SOURCES_WIZARD_STEP));
            const decodedParam = atob(encodedParam);
            
            if ( isICRSRecord ){
                setIcrsRecordId(decodedParam);
            }
            else {
                setCliRecordId(decodedParam);
            }
        }
        catch(ex) {
            services.messageService.showErrorBanner('The provided CLI is invalid. Please select a valid CLI.');
            setIsValidCLI(false);
        }
    }, [encodedParam])

    useEffect(() => {
        if (calcVersionsResult == null) {
            return;
        }
        if (calcVersionsResult.length > 0){
            if (calculationConfigurationState != CONSTANTS.CALCULATION_CONFIGURATION_STATE.JOURNAL_CONFIGURATION
                && calculationConfigurationState != CONSTANTS.CALCULATION_CONFIGURATION_STATE.JOURNAL_REVIEW) {
                dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(CALC_STEPS_WIZARD_STEP));
            }
            dispatch(ACTIONS.SET_CALCULATION_VERSIONS.withPayload(calcVersionsResult));
        }
        else if (!StringUtils.isNullOrEmpty(calculationSource.sourceKeyNumber)) {
            // Check if the role is allowed to create a calculation
            if (!canEdit) {
                services.messageService.showErrorBanner('Your role is not authorized to create a new calculation.');
                setIsValidCLI(false);
                return;
            }
            // If there are no versions for this source then we create a calculation
            dispatch(ACTIONS.SET_NEW_CALCULATION_NUMBER.withPayload(calculationSource.sourceKeyNumber));  
        }
    }, [calcVersionsResult, cliDetails, icrsDetails]);
    
    useEffect(() => {
        if (newCalcResult == null) {
            return;
        }
        if (isICRSRecord){
            setIcrsRecordId(newCalcResult.cliRecordId);
        }
        else {
            setCliRecordId(newCalcResult.cliRecordId);
        }
        // Move to next step upon successful calculation creation
        dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(DATA_SOURCES_WIZARD_STEP));
        dispatch(ACTIONS.SET_CALCULATION_VERSIONS.withPayload([CONSTANTS.CALCULATION_VERSIONS.DRAFT_VERSION]));
        setTimeout(() => {
            dispatch(ACTIONS.SET_REFRESH_CALCULATION_METADATA_FLAG.withPayload(true));
            dispatch(ACTIONS.SET_REFRESH_CLI_DETAILS_FLAG.withPayload(true))
        }, 1000);
    }, [newCalcResult]);

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(cliDetailsError) && StringUtils.isNullOrEmpty(icrsDetailsError)) {
            return;
        }
        dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(SELECT_AGREEMENT_WIZARD_STEP));
        dispatch(ACTIONS.DISABLE_WIZARD_STEP_AND_STEPS_AHEAD.withPayload(2));
    }, [cliDetailsError, icrsDetailsError]);
    
    useEffect(() => {
        if (isEditingTemplate){
            services.messageService.showInfoAutoDismissBanner("This template is now in edit mode!");
        }
    }, [isEditingTemplate]);

    useEffect(() => {
        if (cliDetailsResult == null) {
            return;
        }
        setCLIDetails(cliDetailsResult.cli);
        setCalculationSource(CalculationSource.fromCLI(cliDetailsResult.cli))
        dispatch(ACTIONS.SET_CALCULATION.withPayload(cliDetailsResult.cli));
        if (encodedParam == ENCODED_PARAM_NEW || cliDetailsResult.cli.calculationStatus?.toLowerCase() == CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase()) {
            globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb( pathname, 
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_BREADCRUMB.replace('cli', cliDetailsResult.cli.calculationNumber),
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_TITLE,
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.NEW_CALC_DESCRIPTION
                                                                                                   )));
        } else {
            globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb( pathname,
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_BREADCRUMB.replace('cli', cliDetailsResult.cli.calculationNumber),
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_TITLE,
                                                                                                    CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.EXISTING_CALC_DESCRIPTION
                                                                                                )));
        }
    }, [cliDetailsResult])

    useEffect(() => {
        if (agreementVersionsResult == null) {
            setVersions([]);
            return;
        }
        setVersions(agreementVersionsResult.versions);
    }, [agreementVersionsResult])
    
    useEffect(() => {
        if (selectedWizardStep == null) {
            return;
        }
        var element = document.querySelector(`#${selectedWizardStep.scrollToId}`);
        // scroll to element
        element?.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }, [selectedWizardStep])

    useEffect(() => {
        if (linkedDataSources != null) {
            setShowBothSteps2And3(true);
        }
    }, [linkedDataSources])

    useEffect(() => {
        if (generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.IN_PROGRESS || generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.SUCCEEDED) {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_REVIEW_WIZARD_STEP));
        }
    }, [generatingReviewJournalsStatus])

    useEffect(() => {
        if (calculationConfigurationState === CONSTANTS.CALCULATION_CONFIGURATION_STATE.JOURNAL_CONFIGURATION) {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_SETTINGS_WIZARD_STEP));
        } else if (calculationConfigurationState === CONSTANTS.CALCULATION_CONFIGURATION_STATE.JOURNAL_REVIEW){
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_REVIEW_WIZARD_STEP));
        }
    }, [calculationConfigurationState])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(journalEntriesResult)) {
            return;
        }
        dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_REVIEW_WIZARD_STEP));
        dispatch(ACTIONS.SET_EXECUTION_RESULT_LINES.withPayload(journalEntriesResult));        
    }, [journalEntriesResult])

    useEffect(() => {
        dispatch(ACTIONS.SET_REVERSAL_EXECUTION_RESULT_LINES.withPayload(reversalJournalEntriesResult));
    }, [reversalJournalEntriesResult])

    useEffect(() => {
        // Resetting back to Step 4 if there are no review journals to display after user reset execution period selection
        if (executionPeriodWasTouched && ArrayUtils.isNullOrEmpty(reviewJournals) && executionPeriod == null && calculationConfigurationState === CONSTANTS.CALCULATION_CONFIGURATION_STATE.JOURNAL_REVIEW) {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_SETTINGS_WIZARD_STEP));
            dispatch(ACTIONS.DISABLE_WIZARD_STEP_AND_STEPS_AHEAD.withPayload(JE_REVIEW_WIZARD_STEP.number));
            return;
        }
    }, [reviewJournals, executionPeriod, calculationConfigurationState])

    const isStepNumberSelected = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        return selectedWizardStep.number === number;
    }

    const isStepNumberEnabled = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        const step = wizardSteps.find(x => x.number === number);

        return step != null && step.isEnabled;
    }

    const onStepChanged = function (step: WizardStep) {
        // If "Select TP Agreements" is selected
        if (step.number === 1) {
            dispatch(ACTIONS.RESET.withPayload({isICRSRecord}));
        }
        else {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(step));
        }
    }

    const onDataSourcesCanceled = () => {
        //TODO: Implement here any additional actions when data sources is cancelled
    }

    const onDataSourcesNext = () => {
        dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(CALC_STEPS_WIZARD_STEP));
    }

    const onCalculationStepsCanceled = () => {
        //TODO: Implement here any additional actions when calculation steps is cancelled
    }

    const onCalculationStepsSaved = () => {
    }

    const onJESettingsCanceled = () => {
        //TODO: Implement here any additional actions when JE Settings is cancelled
    }

    const onJESettingsSaved = () => {
        //TODO: Call save API
        //dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_REVIEW_WIZARD_STEP));
    }

    const onReviewCanceled = () => {
        //TODO: Implement here any additional actions when review is cancelled
    }

    
    /**
     * Determines if calc builder step 3 should be displayed. If no data sources are configured yet it should return false.
     * @param dataSourceRecords The list of data sources
     * @returns True if there is at least 1 saved datasource in the list
     */
    function shouldShowCalcBuilderStep3(dataSourceRecords: DataSourceRecord[]) {
        if (ArrayUtils.isNullOrEmpty(dataSourceRecords)){
            return false;
        }
        return !dataSourceRecords[0].isNewRecord;
    }

    return (
        isValidCLI ?
        <CalculationBuilderProvider services={services} state={state} dispatch={dispatch} userProfile={userProfile}>
            <div className="calcBuilder">
                {canEdit && <CalculationBuilderTemplateDownloader/> }
                <SpaceBetween direction="vertical" size="m">
                    { !(!canEdit && encodedParam == ENCODED_PARAM_NEW) && <div key="stepsStripContent" className="stepsStripContainer">
                        <StepsWizardBar {...{selectedWizardStep, wizardSteps, onStepChanged}} />
                    </div> }
                    { selectedWizardStep?.number === 1 && encodedParam == ENCODED_PARAM_NEW? 
                            (isICRSRecord? 
                                <ATPViewRestrictor module={AppModules.CALCULATION_BUILDER} canViewOverride={canEdit}>
                                    <CalculationBuilderSelectICRSRecord isCreating={newCalcLoading}/>
                                </ATPViewRestrictor> 
                            : 
                            <ATPViewRestrictor module={AppModules.CALCULATION_BUILDER} canViewOverride={canEdit}>
                                <CalculationBuilderSelectAgreement key="CalculationBuilderSelectAgreement" isCreating={newCalcLoading}/>
                            </ATPViewRestrictor>
                            )
                        : <React.Fragment>
                            {isStepNumberEnabled(2) &&
                                <>
                                    <CalculationStatusBar />
                                    { isICRSRecord? 
                                        <ICRSRegistrationDetails registration={icrsDetails} expanded={isStepNumberSelected(1)} loading={loading}/> :
                                        <TPAgreementCLIDetails cli={cliDetails} versions={versions} expanded={isStepNumberSelected(1)} loading={loading} /> 
                                    }
                                    <TemplatesContainer loading={loading || calcMetadataLoading || calcMetadataResult == null} />
                                    {selectedCalculationVersion != null && <WizardStepContentWrapper wizardStep={wizardSteps[1]}>
                                        <DataSourcesContainer 
                                            expanded={isStepNumberSelected(2)} 
                                            onCancel={onDataSourcesCanceled} 
                                            onNext={onDataSourcesNext} />
                                    </WizardStepContentWrapper>}
                                </>
                            }
                            {isStepNumberEnabled(3) && shouldShowCalcBuilderStep3(dataSourceRecords) &&
                                <WizardStepContentWrapper wizardStep={wizardSteps[2]}>
                                    <StepsContainer expanded={isStepNumberSelected(3) || showBothSteps2And3} onCancel={onCalculationStepsCanceled} onSave={onCalculationStepsSaved} />
                                </WizardStepContentWrapper>
                            }
                            {isStepNumberEnabled(4) && 
                                <WizardStepContentWrapper wizardStep={wizardSteps[3]}>
                                    <JESettingsContainer expanded={isStepNumberSelected(4)} onCancel={onJESettingsCanceled} onSave={onJESettingsSaved} />
                                </WizardStepContentWrapper>
                            }
                            {isStepNumberEnabled(5) && 
                                <WizardStepContentWrapper wizardStep={wizardSteps[4]}>
                                    <ReviewJournalEntriesContainer calculationSource={calculationSource} expanded={isStepNumberSelected(5)} onCancel={onReviewCanceled} />
                                </WizardStepContentWrapper>
                            }
                            <div className="spacer"></div>
                        </React.Fragment>
                    }
                </SpaceBetween>
                <TPEErrorWatcher services={services} errors={[errorCompanies, errorGlAccounts, errorCostCenters, errorProductLines, errorLocations, errorChannels, 
                    errorProjects, dataSourceLOVsError, calcVersionsError, newCalcError, cliDetailsError, icrsDetailsError, linkedCLIsError, errorVersions, 
                    calcMetadataError, journalEntriesError, journalsError]} />
            </div>
        </CalculationBuilderProvider>
        :
        null
    );
}
