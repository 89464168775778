import CONSTANTS from '../utils/constants';
import AWS from 'aws-sdk';
import { ensureAuthenticated } from '../utils/auth/authenticate';
import CustomDataTableRecord from 'src/models/custom-data-tables/CustomDataTableRecord';
import { CustomCOARow } from 'src/models/custom-coa/CustomCOADefinition';
import ErrorUtils from 'src/utils/ErrorUtils';

let baseUrl = '/api';
let reportBaseUrl = '/report-api';
const environment = process.env.NODE_ENV;

// Common abstraction for calling any API. The parameters used for querying
// the API are all common among the API endpoints, so this method abstracts
// this functionality to make the API methods more readable.
async function _callExternalApi(method: string, url: string, body = null, headers = {}) {
    const auth = await ensureAuthenticated() as any;
    const session = auth.getSignInUserSession();
    const jwtToken = session.getIdToken().getJwtToken();

    // Utility function to check response status and handle errors based on status code
    async function checkStatus(response: any) {
        if (response.status >= 200 && response.status < 300) {
            // If everything looks good
            return response;
        }
        if (response.status === 417) {
            // Lambda API will return status code 417 with a message intended for the final user
            const responseBody = await response.json();
            const error = new Error(responseBody.message);
            error.message = responseBody.message;
            throw error;
        } else if (response.status >= 300 && response.status < 500) {
            // If there are issues where the user could take action (not server side errors)
            const error = new Error(response.statusText);
            error.message = "There was a problem with the request. Error status code: "+response.status;
            throw error;
        } else {
            // All other status codes (mostly 500s will display this generic message)
            console.error(await response.json())
            const error = new Error(CONSTANTS.GENERIC_ERROR_MESSAGE);
            error.message = CONSTANTS.GENERIC_ERROR_MESSAGE;
            throw error;
        }
    }

    const response = await fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            ...headers,
            Authorization: jwtToken,
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: method === 'GET' ? null : JSON.stringify(body),
    });

    await checkStatus(response);

    return response;
}

async function _callBusinessApi(method: string, endpoint: string, body: any | undefined = null, headers = {}) {
    if (environment === CONSTANTS.PRODUCTION) {
        baseUrl = CONSTANTS.ENVIRONMENT_VARIABLES.BUSINESS_LOGIC_API as string;
    }

    const url = `${baseUrl}${endpoint}`;

    const result = await _callExternalApi(method, url, body, headers);

    return result;
}

async function _callReportingApi(method: string, endpoint: string, body: any | undefined = null, headers = {}) {
    if (environment === CONSTANTS.PRODUCTION) {
        reportBaseUrl = CONSTANTS.ENVIRONMENT_VARIABLES.REPORTS_API as string;
    }
    const url = `${reportBaseUrl}${endpoint}`;

    const result = await _callExternalApi(method, url, body, headers);

    return result;
}

async function _downloadFromS3(s3Bucket: string, s3key: string) {
    // TODO - move S3 region to CloudFormation params
    const s3 = new AWS.S3({
        region: 'us-east-1'
    });
    const params = {
        Bucket: s3Bucket,
        Key: s3key,
    };
    const data = await s3.getObject(params).promise();
    return data.Body != undefined ? JSON.parse(data.Body.toString()) : null;
}

async function _uploadToS3(s3Bucket: string, s3key: string, file: File) {
    const s3 = new AWS.S3();
    const params = {
        Bucket: s3Bucket,
        Key: s3key,
        Body: file,
        ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    try{
        const status = await s3.upload(params).promise();
        return `SUCCESS: ${status.Key}`;
    }catch(ex){
        if (ErrorUtils.getMessage(ex).startsWith('Missing credentials')) {
            throw new Error('Credentials timed out due to inactivity. Please refresh the page and try again.')
        } else {
            throw ex;
        }
    }
}

const API = {
    async callExternalApi(method: string, url: string, body: any | undefined = null, headers = {}) {
        return _callExternalApi(method, url, body, headers);
    },
    async uploadToS3(s3Bucket: string, s3key: string, file: File){
        return _uploadToS3(s3Bucket,s3key,file);
    },
    async downloadFromS3(s3Bucket: string, s3Key: string) {
        return _downloadFromS3(s3Bucket, s3Key);
    },
    async getAllAgreements() {
        const result = await _callBusinessApi('GET', '/lov/agreement-numbers');
        return result;
    },
    async getAgreementStatusCategories() {
        const result = await _callBusinessApi('GET', '/lov/agreement-status-categories');
        return result;
    },
    async getAgreementLines(agreementNumber: string) {
        const result = await _callBusinessApi('GET', `/lov/agreement-line-numbers/${agreementNumber}`);
        return result;
    },
    async getAgreementDetails(agreement: string) {
        const result = await _callBusinessApi('GET', `/agreements/${agreement}`);
        return result;
    },
    async getAgreementVersions(recordId: string) {
        const result = await _callBusinessApi('GET', `/agreements/${recordId}/versions`);
        return result;
    },
    async getCalculationLines(agreementLineNumber: string) {
        const result = await _callBusinessApi('GET', `/lov/calculation-numbers/${agreementLineNumber}`);
        return result;
    },
    async getCLIDetails(recordId: string) {
        const result = await _callBusinessApi('GET', `/cli/${recordId}`);
        return result;
    },
    async getCLIs(status?: string, workbookId?: string) {
        const parameters = workbookId == null ? '' : `workbookId=${workbookId}&`.concat(status == null ? '' : `status=${status}`);
        const queryString = parameters == '' ? '' : `?${parameters}`
        const result = await _callBusinessApi('GET', `/clis${queryString}`);
        return result;
    },
    async searchCalculations(payload: any) {
        const result = await _callBusinessApi('POST', '/search/cli', payload);
        return result;
    },
    async searchAgreements(payload: any) {
        const result = await _callBusinessApi('POST', '/search/agreements', payload);
        return result;
    },
    async searchICRS(payload: any) {
        const result = await _callBusinessApi('POST', '/lov/icrs', payload);
        return result;
    },
    async getUserProfile(userName: string) {
        const result = await _callBusinessApi('GET', `/user-profiles/${userName}`);
        return result;
    },
    async saveUserProfile(payload: any) {
        const result = await _callBusinessApi('PUT', '/user-profiles', payload);
        return result;
    },
    async getCalculationStatuses() {
        const result = await _callBusinessApi('GET', '/lov/calculation-statuses');
        return result;
    },
    async getAccountingOwners() {
        const result = await _callBusinessApi('GET', '/lov/accounting-owners');
        return result;
    },
    async getTaxOwners() {
        const result = await _callBusinessApi('GET', '/lov/tax-owners');
        return result;
    },
    async getDefaultWorklists() {
        const result = await _callBusinessApi('GET', '/lov/default-worklists');
        return result;
    },
    async getDataSourceTypes() {
        const result = await _callBusinessApi('GET', '/lov/data-source-types');
        return result;
    },
    async getPeriods() {
        const result = await _callBusinessApi('GET', '/lov/periods');
        return result;
    },
    async getCurrencyCodes() {
        const result = await _callBusinessApi('GET', '/lov/currency-codes');
        return result;
    },
    async getCurrencyConversionTypes() {
        const result = await _callBusinessApi('GET', '/lov/currency-conversion-types');
        return result;
    },
    async getCoaTypes() {
        const result = await _callBusinessApi('GET', '/lov/coa-types');
        return result;
    },
    async getCoaData(segment: string) {
        const result = await _callBusinessApi('GET', `/lov/coa-data/${segment}`);
        return result;
    },
    async getCalculationVersions(calculationNumber: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions`);
        return result;
    },
    async getCalculationMetadata(calculationNumber: string, version: number) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions/${version}`);
        return result;
    },
    async getWorkbooks() {
        const result = await _callBusinessApi('GET', '/workbooks');
        return result;
    },
    async getTPEUsers() {
        const result = await _callBusinessApi('GET', '/users');
        return result;
    },
    async updateCLICalcAssignee(payload: any) {
        const result = await _callBusinessApi('PUT', `/cli/${payload.cliRecordId}/calc-assignee`, payload);
        return result;
    },
    async updateCLICalcBuilder(payload: any) {
        const result = await _callBusinessApi('PUT', `/cli/${payload.cliRecordId}/calc-builder`, payload);
        return result;
    },
    async updateCLIWorkbook(payload: any) {
        const result = await _callBusinessApi('PUT', `/cli/${payload.cliRecordId}/workbook`, payload);
        return result;
    },
    async getCalcBuilderDataSources(calculationNumber: string, version: number, calculationExecutionId?: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/version/${version}/data-sources${calculationExecutionId == null ? '' : '?calculationExecutionId=' + calculationExecutionId}`);
        return result;
    },
    async createDataSourceRecord(calculationNumber:string, payload: any) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/data-sources`, payload);
        return result;
    },
    async updateDataSourceRecord(calculationNumber:string, payload: any) {
        const result = await _callBusinessApi('PUT', `/calculations/${calculationNumber}/data-sources`, payload);
        return result;
    },
    async deleteDataSourceRecord(calculationNumber: string, datasourceId: string) {
        const result = await _callBusinessApi('DELETE', `/calculations/${calculationNumber}/data-sources/${datasourceId}`, {});
        return result;
    },
    async createCalculation(payload: any) {
        const result = await _callBusinessApi('POST', `/calculations`, payload);
        return result;
    },
    async createCalculationTemplate(payload: any) {
        const result = await _callBusinessApi('POST', `/templates`, payload);
        return result;
    },
    async renameCalculationTemplate(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/rename`, payload);
        return result;
    },
    async getCalcSteps(calculationNumber: string, version: number, calculationExecutionId?: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions/${version}/steps${calculationExecutionId == null ? '' : '?calculationExecutionId=' + calculationExecutionId}`);
        return result;
    },
    async createCalculationStep(calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/steps`, payload);
        return result;
    },
    async updateCalculationStep(calculationNumber: string, stepId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/calculations/${calculationNumber}/steps/${stepId}`, payload);
        return result;
    },
    async deleteCalculationStep(calculationNumber: string, stepId: string) {
        const result = await _callBusinessApi(
            'DELETE',
            `/calculations/${calculationNumber}/steps/${stepId}`,
            {} as any // Doing this as the server expects a payload for DELETE method
        );
        return result;
    },
    async validateCalcSteps(calculationNumber: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/validate`);
        return result;
    },
    async cancelCalcStepsChanges(calculationNumber: string) {
        const result = await _callBusinessApi(
            'PATCH',
            `/calculations/${calculationNumber}/cancel`,
            {} as any // Doing this as the server expects a payload for PATCH method
        );
        return result;
    },
    async runCalculationSteps(calculationNumber: string, version: number, generateJournals: boolean) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/versions/${version}/run`, {generateJournals});
        return result;
    },
    async getCalcStepComments(calculationNumber: string, version: number, stepId: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions/${version}/steps/${stepId}/comments`);
        return result;
    },
    async createCalcStepComment(calculationNumber: string, version: number, stepId: string, comment: string) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/versions/${version}/steps/${stepId}/comments`, {
            calculationNumber,
            version,
            stepId,
            comment
        });
        return result;
    },
    async getDataSourceComments(calculationNumber: string, version: number, dataSourceId: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions/${version}/data-sources/${dataSourceId}/comments`);
        return result;
    },
    async createDataSourceComment(calculationNumber: string, version: number, datasourceId: string, comment: string) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/versions/${version}/data-sources/${datasourceId}/comments`, {
            calculationNumber,
            datasourceId,
            comment
        });
        return result;
    },
    async getLinkedCLIs(templateId: string) {
        const result = await _callBusinessApi('GET', `/templates/${templateId}/calculations`);
        return result;
    },
    async unlinkCalculationFromTemplate(calculationNumber: string, templateId: string) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/calculations/${calculationNumber}/unlink`, {calculationNumber, templateId});
        return result;
    },
    async getTemplates(workbookId?: string) {
        if (workbookId != null) {
            const result = await _callBusinessApi('GET', `/templates?workbookId=${workbookId}`);
            return result;
        } else {
            const result = await _callBusinessApi('GET', '/templates');
            return result;
        }
    },
    async linkCalculationToTemplate(calculationNumber: string, templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/calculations/${calculationNumber}/link`, payload);
        return result;
    },
    async bulkLinkCalculationsToTemplate(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/bulkLink`, payload);
        return result;
    },
    async cloneCalculation(calculationNumber: string, templateId: string) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/calculations/${calculationNumber}/clone`, {calculationNumber, templateId});
        return result;
    },
    async bulkCloneCalculation(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/bulkClone`, payload);
        return result;
    },
    async editTemplate(templateId: string, calculationNumber: string) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/edit`, {templateId, calculationNumber});
        return result;
    },
    async saveTemplate(templateId: string) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/save`, {templateId});
        return result;
    },
    async cancelTemplateChanges(templateId: string) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/cancel`, {templateId});
        return result;
    },
    async getJournals(calculationNumber: string, version: number, journalLineType: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/versions/${version}/journals/${journalLineType}`);
        return result;
    },
    async updateJournals(calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('PATCH', `/calculations/${calculationNumber}/journals`, payload);
        return result;
    },
    async uploadCustomDataTable(s3Key: string, newTable: boolean){
        const result = await _callBusinessApi('POST', '/custom-data-tables/upload', {'key': s3Key, 'newTable': newTable});
        return result;
    },
    async uploadCalculation(s3Key: string, calculationNumber: string){
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/upload-calculation`, {'key': s3Key, 'calculationNumber': calculationNumber});
        return result;
    },
    async searchCustomDataTables(payload: any) {
        const result = await _callBusinessApi('POST', '/search/custom-data-tables', payload);
        return result;
    },
    async searchCustomDataTableRows(payload: any) {
        const result = await _callBusinessApi('POST', '/search/custom-data-table-rows', payload);
        return result;
    },
    async createCustomDataTable(tableName: string) {
        const result = await _callBusinessApi('POST', '/create/custom-data-tables', {tableName: tableName});
        return result;
    },
    async renameCustomDataTable(tableId: string, newTableName: string) {
        const result = await _callBusinessApi('POST', '/custom-data-tables/rename', {tableId, newTableName});
        return result;
    },
    async downloadFile(s3Bucket: string, s3Key: string) {
        const result = await _callBusinessApi('POST', '/download-file', {bucket: s3Bucket, key: s3Key});
        return result;
    },
    async saveCustomDataTable(tableId: string | null, rows: CustomDataTableRecord[], validate: boolean) {
        const result = await _callBusinessApi('POST', '/custom-data-tables/save', {tableId, rows, validate});
        return result;
    },
    async uploadCustomCOADefinition(key: string, coaSegment: string, newTable: boolean) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/upload', {key, coaSegment, newTable});
        return result;
    },
    async createCustomCOADefinition(tableName: string, coaSegment: string) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/create', {tableName, coaSegment});
        return result;
    },
    async renameCustomCOADefinition(tableId: string, newTableName: string) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/rename', {tableId, newTableName});
        return result;
    },
    async saveCustomCOADefinition(tableId: string, rows: CustomCOARow[]) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/save', {tableId, rows});
        return result;
    },
    async getCustomCOADefinitionsForSegment(coaSegment: string) {
        const result = await _callBusinessApi('GET', `/custom-coa-definitions-for-segment/${coaSegment}`);
        return result;
    },
    async getClassifications(tableId: string) {
        const result = await _callBusinessApi('GET', `/custom-coa-definitions/${tableId}/classifications`);
        return result;
    },
    async searchCustomCoaDefinitions(payload: any) {
        const result = await _callBusinessApi('POST', '/search/custom-coa-definitions', payload);
        return result;
    },
    async searchCustomCoaDefinitionRows(payload: any) {
        const result = await _callBusinessApi('POST', '/search/custom-coa-definition-rows', payload);
        return result;
    },
    async dryRunCalculation(calculationNumber: string, calculationVersion: number, generateTaxJournals: boolean) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/versions/${calculationVersion}/dry-run`, {
            calculationNumber,
            calculationVersion,
            generateTaxJournals
        });
        return result;
    },
    async getCalculationExecution(calculationNumber: string, period: string) {
        const result = await _callBusinessApi('GET', `/calculations/${calculationNumber}/executions/${period}`);
        return result;
    },
    async getJournalEntries(calculationExecutionId: string) {
        const result = await _callBusinessApi('GET', `/journalEntries/${calculationExecutionId}`);
        return result;
    },
    async downloadCustomDataTable(tableId: string) {
        const result = await _callBusinessApi('POST', '/custom-data-tables/download', {tableId});
        return result;
    },
    async downloadCustomCOADefinition(tableId: string) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/download', {tableId});
        return result;
    },
    async discardDraft(calculationNumber: string) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/discard-draft`, {calculationNumber});
        return result;
    },
    async getCustomDataTableAllowDeleteFlag(tableId: string) {
        const result = await _callBusinessApi('GET', `/custom-data-tables/${tableId}/allow-delete-flag`);
        return result;
    },
    async deleteCustomDataTable(tableId: string) {
        const result = await _callBusinessApi('DELETE', `/custom-data-tables/${tableId}`, {tableId});
        return result;
    },
    async archiveCustomDataTable(tableId: string) {
        const result = await _callBusinessApi('POST', '/custom-data-tables/archive', {tableId});
        return result;
    },
    async getCustomCOADefinitionAllowDeleteFlag(tableId: string) {
        const result = await _callBusinessApi('GET', `/custom-coa-definitions/${tableId}/allow-delete-flag`);
        return result;
    },
    async deleteCustomCOADefinition(tableId: string) {
        const result = await _callBusinessApi('DELETE', `/custom-coa-definitions/${tableId}`, {tableId});
        return result;
    },
    async archiveCustomCOADefinition(tableId: string) {
        const result = await _callBusinessApi('POST', '/custom-coa-definitions/archive', {tableId});
        return result;
    },
    async getReportingPeriods() {
        const result = await _callReportingApi('GET', '/GetReportingPeriods');
        return result;
    },
    async generateReport(payload: any) {
        const result = await _callReportingApi('POST', '/GenerateReport', payload);
        return result;
    },
    async listReports(username?: string) {
        if (username != null) {
            const result = await _callReportingApi('GET', `/ListReports?userId=${username}`);
            return result;
        } else {
            const result = await _callReportingApi('GET', `/ListReports`);
            return result;
        }
    },
    async downloadReport(payload: any) {
        const result = await _callReportingApi('POST', '/DownloadReport', payload);
        return result;
    },
    async searchTaxAuditRecords(payload: any) {
        const result = await _callReportingApi('POST', '/SearchTaxAuditRecords', payload);
        return result;
    },
    async getCLIExecutionRecords(payload: any) {
        const result = await _callReportingApi('POST', '/GetCLIExecutionRecords', payload);
        return result;
    },
    async downloadCLIExecutionRecords(periodId: string) {
        const result = await _callReportingApi('GET', `/DownloadCLIExecutionRecords?periodId=${periodId}`);
        return result;
    },
    async getTaxAuditRecordDetails(taxJournalHeaderId: string) {
        const result = await _callReportingApi('GET', `/GetTaxAuditRecordDetails/${taxJournalHeaderId}`);
        return result;
    },
    async downloadTaxAuditRecord(payload: any) {
        const result = await _callReportingApi('POST', '/DownloadTaxAuditRecord', payload);
        return result;
    },
    async fetchIndirectTaxChangeRecords(payload: any) {
        const result = await _callReportingApi('POST', '/FetchIndirectTaxChangeRecords', payload);
        return result;
    },
    async fetchTPTaxVarianceRecords(payload: any) {
        const result = await _callReportingApi('POST', '/GetTPTaxVarianceEntries', payload);
        return result;
    },
    async getTPReportFilters(payload: any) {
        const result = await _callReportingApi('POST', '/GetTPReportFilters', payload);
        return result;
    },
    async fetchTPReportsSummary(payload: any) {
        const result = await _callReportingApi('POST', '/GetTPReportsSummary', payload);
        return result;
    },
    async getCOADataBySegment(payload: string) {
        const result = await _callReportingApi('GET', `/CoaData/${payload}`);
        return result;
    },
    async downloadIndirectTaxChangeRecords(reportId: string) {
        const result = await _callReportingApi('GET', `/DownloadIndirectTaxChangeRecords?reportId=${reportId}`);
        return result;
    },
    async getTPEntries(calculationNumber: string, ytdIndia: boolean) {
        const result = await _callBusinessApi('POST', `/lov/tp-entries/${calculationNumber}`, {calculationNumber, ytdIndia});
        return result;
    },
    async submitCalculationForReview(calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/submit-for-review`, payload);
        return result;
    },
    async getCLIExecutionGroups() {
        const result = await _callBusinessApi('GET', `/lov/cli-execution-groups/`);
        return result;
    },
    async saveCalculationAttribute(calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('PATCH', `/calculations/${calculationNumber}/attribute`, payload);
        return result;
    },
    async getJEStatuses() {
        const result = await _callBusinessApi('GET', '/lov/je-statuses/');
        return result;
    },
    async rejectCalculationDraft(calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/reject-draft`, payload);
        return result;
    },
    async approveCalculation(calculationNumber: string) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/approve`, {calculationNumber});
        return result;
    },
    async getCLIsByProviderCompanyCode(providerCompanyCode: string, calculationStatus: string){
        const result = await _callReportingApi('GET', `/GetCLIsByProvider?providerCompanyCode=${providerCompanyCode}&calculationStatus=${calculationStatus}`)
        return result;
    },
    async saveStandardAllocation(templateId: string, calculationNumber: string, payload: any) {
        const result = await _callBusinessApi('POST', `/templates/${templateId}/calculations/${calculationNumber}/standard-allocation`, payload);
        return result;
    },
    async deleteDatasourceStandardAllocation(templateId: string, datasourceId: string) {
        const result = await _callBusinessApi('DELETE', `/templates/${templateId}/data-sources/${datasourceId}/standard-allocation`, {});
        return result;
    },
    async deleteStepStandardAllocation(templateId: string, stepId: string) {
        const result = await _callBusinessApi('DELETE', `/templates/${templateId}/steps/${stepId}/standard-allocation`, {});
        return result;
    },
    async fetchReportData(payload: any) {
        const result = await _callReportingApi('POST', '/FetchReportData', payload);
        return result;
    },
    async getCLIAPI() {
        const result = await _callBusinessApi('GET', '/clis?Active');
        return result;
    },
    async getAgreementStatus() {
        const result = await _callBusinessApi('GET', '/lov/agreement-status');
        return result;
    },
    async getCalculationStatus() {
        const result = await _callBusinessApi('GET', '/lov/calculation-statuses');
        return result;
    },
    async getGroupExecutionCLIDetails(groupNumber: string, period: string) {
        const result = await _callBusinessApi('GET', `/mec/group-execution-cli-details/${period}/${groupNumber}`);
        return result;
    },
    async getTPAllocationTemplatesAndWorksheets() {
        const result = await _callBusinessApi('GET', '/tp-allocation-templates-and-worksheets');
        return result;
    },
    async getTPAllocationWorksheetDetails(worksheetId: string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}`);
        return result;
    },
    async getTPAllocationWorksheetDataSources(worksheetId: string, worksheetVersion: number, executionPeriodId?: string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/versions/${worksheetVersion}/data-sources?periodId=${executionPeriodId}`);
        return result;
    },
    async getTPAllocationWorksheetTotals(worksheetId: string, worksheetVersion: number, executionPeriodId?: string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/versions/${worksheetVersion}/totals?periodId=${executionPeriodId}`);
        return result;
    },
    async getTPAllocationWorksheetGroups(worksheetId: string, worksheetVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/versions/${worksheetVersion}/allocation-groups`);
        return result;
    },
    async getTPAllocationWorksheetGroupFormulas(worksheetId: string, worksheetVersion: number, allocationGroupId: string, executionPeriodId?: string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/versions/${worksheetVersion}/allocation-groups/${allocationGroupId}/allocation-formulas?periodId=${executionPeriodId}`);
        return result;
    },
    async searchTPAllocationWorksheets(payload: any) {
        const result = await _callBusinessApi('POST', '/search/worksheets', payload);
        return result;
    },
    async searchTPAllocationWorksheetFormulas(payload: any) {
        const result = await _callBusinessApi('POST', '/search/worksheet-allocation-formulas', payload);
        return result;
    },
    async getTPAllocationWorksheetTemplateDetails(templateId: string) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}`);
        return result;
    },
    async getTPAllocationWorksheetTemplatePlaceholders(templateId: string, templateVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}/versions/${templateVersion}/placeholders`);
        return result;
    },
    async getTPAllocationWorksheetTemplateDataSources(templateId: string, templateVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}/versions/${templateVersion}/data-sources`);
        return result;
    },
    async getTPAllocationWorksheetTemplateTotals(templateId: string, templateVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}/versions/${templateVersion}/totals`);
        return result;
    },
    async getTPAllocationWorksheetTemplateGroups(templateId: string, templateVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}/versions/${templateVersion}/allocation-groups`);
        return result;
    },
    async getTPAllocationWorksheetTemplateGroupFormulas(templateId: string, templateVersion: number, allocationGroupId: string) {
        const result = await _callBusinessApi('GET', `/worksheet-templates/${templateId}/versions/${templateVersion}/allocation-groups/${allocationGroupId}/allocation-formulas`);
        return result;
    },
    async getTPAllocationWorksheetPlaceholders(worksheetId: string, worksheetVersion: number) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/versions/${worksheetVersion}/placeholders`);
        return result;
    },
    async createWorksheetDatasetRecord(worksheetId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/data-sources`, payload);
        return result;
    },
    async updateWorksheetDatasetRecord(worksheetId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheets/${worksheetId}/data-sources/${payload.dataSourceId}`, payload);
        return result;
    },
    async deleteWorksheetDatasetRecord(worksheetId: string, dataSourceId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheets/${worksheetId}/data-sources/${dataSourceId}`,{worksheetId, dataSourceId});
        return result;
    },
    async createWorksheetTotal(worksheetId:string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/totals`, payload);
        return result;
    },
    async updateWorksheetTotal(worksheetId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheets/${worksheetId}/totals/${payload.totalId}`, payload);
        return result;
    },
    async deleteWorksheetTotal(worksheetId: string, totalId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheets/${worksheetId}/totals/${totalId}`,{worksheetId, totalId});
        return result;
    },
    async createWorksheetAllocationGroup(worksheetId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/allocation-groups`, payload);
        return result;
    },
    async updateWorksheetAllocationGroup(worksheetId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheets/${worksheetId}/allocation-groups/${payload.allocationGroupId}`, payload);
        return result;
    },
    async deleteWorksheetAllocationGroup(worksheetId: string, allocationGroupId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheets/${worksheetId}/allocation-groups/${allocationGroupId}`,{worksheetId, allocationGroupId});
        return result;
    },    
    async createWorksheetAllocationGroupFormula(worksheetId: string, allocationGroupId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/allocation-groups/${allocationGroupId}/allocation-formulas`, payload);
        return result;
    },
    async updateWorksheetAllocationGroupFormula(worksheetId: string, allocationGroupId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheets/${worksheetId}/allocation-groups/${allocationGroupId}/allocation-formulas/${payload.allocationFormulaId}`, payload);
        return result;
    },
    async deleteWorksheetAllocationGroupFormula(worksheetId: string, allocationGroupId: string, allocationFormulaId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheets/${worksheetId}/allocation-groups/${allocationGroupId}/allocation-formulas/${allocationFormulaId}`,{worksheetId, allocationGroupId, allocationFormulaId});
        return result;
    },
    async createWorksheet(payload: any) {
        const result = await _callBusinessApi('POST', '/worksheets', payload);
        return result;
    },
    async createWorksheetTemplate(templateName: string) {
        const result = await _callBusinessApi('POST', '/worksheet-templates', { templateName });
        return result;
    },
    async createTPAllocationWorksheetTemplatePlaceholder(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/placeholders`, payload);
        return result;
    },
    async updateTPAllocationWorksheetTemplatePlaceholder(templateId: string, placeholderId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheet-templates/${templateId}/placeholders/${placeholderId}`, payload);
        return result;
    },
    async deleteTPAllocationWorksheetTemplatePlaceholder(templateId: string, placeholderId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheet-templates/${templateId}/placeholders/${placeholderId}`, {templateId, placeholderId});
        return result;
    },
    async submitWorksheet(worksheetId: string, notes: string) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/submit-for-review`, { worksheetId, notes });
        return result;
    },
    async approveWorksheet(worksheetId: string) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/approve`, { worksheetId });
        return result;
    },
    async rejectWorksheet(worksheetId: string, notes: string) {
        const result = await _callBusinessApi('POST', `/worksheets/${worksheetId}/reject`, { worksheetId, notes });
        return result;
    },
    async validateWorksheet(worksheetId:string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/validate`);
        return result;
    },
    async createWorksheetTemplateDatasetRecord(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/data-sources`, payload);
        return result;
    },
    async updateWorksheetTemplateDatasetRecord(templateId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheet-templates/${templateId}/data-sources/${payload.dataSourceId}`, payload);
        return result;
    },
    async deleteWorksheetTemplateDatasetRecord(templateId: string, dataSourceId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheet-templates/${templateId}/data-sources/${dataSourceId}`,{templateId, dataSourceId});
        return result;
    },
    async createWorksheetTemplateTotal(templateId:string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/totals`, payload);
        return result;
    },
    async updateWorksheetTemplateTotal(templateId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheet-templates/${templateId}/totals/${payload.totalId}`, payload);
        return result;
    },
    async deleteWorksheetTemplateTotal(templateId: string, totalId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheet-templates/${templateId}/totals/${totalId}`,{templateId, totalId});
        return result;
    },
    async createWorksheetTemplateAllocationGroup(templateId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/allocation-groups`, payload);
        return result;
    },
    async updateWorksheetTemplateAllocationGroup(templateId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheet-templates/${templateId}/allocation-groups/${payload.allocationGroupId}`, payload);
        return result;
    },
    async deleteWorksheetTemplateAllocationGroup(templateId: string, allocationGroupId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheet-templates/${templateId}/allocation-groups/${allocationGroupId}`,{templateId, allocationGroupId});
        return result;
    },    
    async createWorksheetTemplateAllocationGroupFormula(templateId: string, allocationGroupId: string, payload: any) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/allocation-groups/${allocationGroupId}/allocation-formulas`, payload);
        return result;
    },
    async updateWorksheetTemplateAllocationGroupFormula(templateId: string, allocationGroupId: string, payload: any) {
        const result = await _callBusinessApi('PUT', `/worksheet-templates/${templateId}/allocation-groups/${allocationGroupId}/allocation-formulas/${payload.allocationFormulaId}`, payload);
        return result;
    },
    async deleteWorksheetTemplateAllocationGroupFormula(templateId: string, allocationGroupId: string, allocationFormulaId: string) {
        const result = await _callBusinessApi('DELETE', `/worksheet-templates/${templateId}/allocation-groups/${allocationGroupId}/allocation-formulas/${allocationFormulaId}`,{templateId, allocationGroupId, allocationFormulaId});
        return result;
    },
    async submitWorksheetTemplate(templateId: string, notes: string) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/submit-for-review`, { templateId, notes });
        return result;
    },
    async approveWorksheetTemplate(templateId: string) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/approve`, { templateId });
        return result;
    },
    async rejectWorksheetTemplate(templateId: string, notes: string) {
        const result = await _callBusinessApi('POST', `/worksheet-templates/${templateId}/reject`, { templateId, notes });
        return result;
    },
    async getMECStatusData(period: string) {
        const result = await _callBusinessApi('GET', `/mec/latest-group-execution-status/${period}`);
        return result;
    },
    async getBulkReversals(period: string) {
        const result = await _callBusinessApi('GET', `/mec/bulk-reversals/${period}`);
        return result;
    },
    async searchItemForReversal(period: string, workbookId?: string, calculationNumber?: string) {
        const result = await _callBusinessApi('POST', '/search/mec/item-for-reversal', { period, workbookId, calculationNumber });
        return result;
    },
    async getLastGLDataIngestionTimestamp() {
        const result = await _callBusinessApi('GET', '/mec/last-gl-data-ingestion-timestamp');
        return result;
    },
    async getICRegistration(registrationNumber: string) {
        const result = await _callBusinessApi('GET', `/icrs/${registrationNumber}`);
        return result;
    },
    async getLatestWorksheetExecutionVersion(worksheetId: string, periodId: string) {
        const result = await _callBusinessApi('GET', `/worksheets/${worksheetId}/executions/${periodId}`);
        return result;
    },
    async getOrAssignExecutionGruop(calculationNumber: string) {
        const result = await _callBusinessApi('POST', `/calculations/${calculationNumber}/execution-group`, { calculationNumber });
        return result;
    },
    async saveCalculationExecutionGroup(calculationNumber: string, executionGroup: string) {
        const result = await _callBusinessApi('PATCH', `/calculations/${calculationNumber}/execution-group`, { calculationNumber, executionGroup });
        return result;
    }
}

export default API;