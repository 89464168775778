import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DashboardView from 'src/components/dashboard/DashboardView';
import CalculationsLanding from 'src/components/calculations/CalculationsLanding';
import AgreementsView from 'src/components/agreements/AgreementsView';
import ReportDetails from "src/components/reports/ReportDetails";
import DashboardService from 'src/services/dashboard/DashboardService';
import ServiceCollection from 'src/services/ServiceCollection';
import SearchCalculations from 'src/components/calculations/SearchCalculations';
import { UserProfile } from 'src/models/users/UserProfile';
import { UserIdentity } from 'src/models/users/UserIdentity';
import AgreementDetails from 'src/components/agreements/AgreementDetails';
import CalculationBuilderView from 'src/components/calculation-builder/CalculationBuilderView';
import CustomAppLayout from 'src/components/CustomAppLayout';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { GlobalState, initialState } from 'src/services/global/GlobalState';
import { globalReducer, GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import TPEAction from 'src/models/common/TPEAction';
import ReadOnlyCalculationView from "src/components/calculations/ReadOnlyCalculationView";
import CustomTablesView from "src/components/custom-tables/TablesView";
import CustomCOAView from "src/components/custom-coa/TablesView";
import LinkToTemplateView from "src/components/templates/link/LinkToTemplateView";
import CustomTableRecordsView from 'src/components/custom-tables/RecordsView';
import CustomCOARowsView from 'src/components/custom-coa/RowsView';
import ReportingHistoryTableView from "src/components/reports/ReportingHistoryTableView";
import TaxAuditRecordsView from 'src/components/reports/taxAuditRecord/TaxAuditRecordsView';
import TaxAuditRecordDetailsView from "src/components/reports/taxAuditRecord/TaxAuditRecordDetailsView";
import CloneCalculationsView from "src/components/templates/link/CloneCalculationsView";
import TPTaxReportView from "src/components/reports/TPTaxReportView";
import ReportsTabbedView from "src/components/reports/prevalidation/ReportsTabbedView";
import MECView from 'src/components/mec/MECView';
import TPAllocationView from 'src/components/tp-allocation/TPAllocatonView';
import StandaloneWorksheetView from 'src/components/tp-allocation/worksheets/StandaloneWorksheetView';
import LinkedWorksheetView from 'src/components/tp-allocation/worksheets/LinkedWorksheetView';
import WorksheetTemplateView from 'src/components/tp-allocation/worksheet-templates/WorksheetTemplateView';
import ReportDashboard from "src/components/reports/reportDashboard/ReportDashboard";
import 'src/assets/styles/EntityStatusBar.scss';
import 'src/assets/styles/Dropdowns.scss';
import 'src/assets/styles/Inputs.scss';
import 'src/assets/styles/Buttons.scss';
import 'src/assets/styles/Radios.scss';
import 'src/assets/styles/Containers.scss';
import 'src/assets/styles/Layouts.scss';
import 'src/assets/styles/TextFilters.scss';
import 'src/assets/styles/Tabs.scss';
import 'src/assets/styles/Spans.scss';
import 'src/assets/styles/Divs.scss';
import 'src/assets/styles/Popovers.scss';
import 'src/assets/styles/StatusIndicators.scss';
import 'src/assets/styles/Autosuggests.scss';
import 'src/assets/styles/BulkAction.scss';
import { AppModules, AppRoles } from 'src/models/permissions/RolePermissions';
import { getPermissions } from './AppPermissions';
import { ATPViewRestrictor } from './shared/ATPViewRestrictor';
import BulkActionsView from './calculations/BulkActionsView';
import CONSTANTS from 'src/utils/constants';
import TPTaxVarianceReportView from './reports/tpTaxVarianceReportDashboard/TPTaxVarianceReportView';
import TPTaxLefReportView from './reports/lefReport/TPTaxLefReportView';
import TPTaxCwbReportView from './reports/cwbReport/TPTaxCwbReportView';
import PeriodChargesView from './period-charges/PeriodChargesView';


export const DashboardContext = React.createContext<DashboardService | undefined>(undefined);

/**
 * Defines the properties available inside the global context
 */
export type GlobalContextType = {
    globalState: GlobalState,
    globalDispatch: React.Dispatch<TPEAction>,
    userProfile: UserProfile
}

/**
 * It takes care of "injecting" the context value in the children
 * @param props Context provider props
 * @returns The children of this fuincional component wrapped with the ContextProvided value
 */
const GlobalContexProvider = (props: any) => {
    const { state, dispatch, userProfile, children } = props;
    const providerValue = React.useMemo(() => ({
        globalState: state,
        globalDispatch: dispatch,
        userProfile
    }), [state, dispatch, userProfile]);
    return (
        <GlobalAppContext.Provider value={providerValue}>
            {children}
        </GlobalAppContext.Provider>
    );
}
/**
 * The global context being shared to any component that needs it
 */
export const GlobalAppContext = React.createContext(null as unknown as GlobalContextType);

export default function App(props: { serviceCollection: ServiceCollection, userIdentity: UserIdentity }) {
    const { serviceCollection, userIdentity } = props;
    const [userProfile, userProfileLoading, userProfileError] = serviceCollection.usersService.getUserProfile(userIdentity.alias);
    const [sharedUserProfile, setSharedUserProfile] = useState(null as unknown as UserProfile);
    const [canSimulate, setCanSimulate] = useState(false);
    const [globalState, globalDispatch] = useReducerWithLogger(globalReducer, initialState);
    const { simulatedUserProfile } = globalState;
    // This is a silent save. No action is needed if it fails or succeeds
    const [saveUserProfileResult, isSavingUserProfile, saveUserProfileError] = serviceCollection.usersService.saveUserProfile(sharedUserProfile);

    useEffect(() => {
        // Initializing the service for banner methods
        serviceCollection.messageService.setupForBanner(globalDispatch,GLOBAL_ACTIONS.ADD_BANNER_MESSAGE, GLOBAL_ACTIONS.REMOVE_BANNER_MESSAGE);
    }, []);

    useEffect(() => {
        if (userProfile == null) {
            return;
        }
        userProfile.firstName = userIdentity.givenName;
        userProfile.lastName = userIdentity.familyName;
        userProfile.user = userIdentity.alias;
        userProfile.roles = userIdentity.roles;
        
        const { canSimulate } = getPermissions(AppModules.APP, userProfile);
        setSharedUserProfile(userProfile);
        setCanSimulate(canSimulate);
    }, [userProfile]);

    useEffect(() => {
        if (simulatedUserProfile == null) {
            setSharedUserProfile(userProfile)
        } else {
            setSharedUserProfile(simulatedUserProfile)
        }
    }, [simulatedUserProfile])

    const isNafnUser = serviceCollection.usersService.hasRole(AppRoles.NAFN, userProfile?.roles);

    return (
        <GlobalContexProvider state={globalState} dispatch={globalDispatch} userProfile={sharedUserProfile}>
            <CustomAppLayout userProfile={sharedUserProfile} isLoading={userProfileLoading} isNafnUser={isNafnUser} canSimulate={canSimulate}>
                        <Switch>
                            <Route exact path="/">
                                <DashboardContext.Provider value={serviceCollection.dashboardService}>
                                    <ATPViewRestrictor module={AppModules.DASHBOARD}><DashboardView /></ATPViewRestrictor>
                                </DashboardContext.Provider>
                            </Route>
                            <Route exact path="/reports">
                                <ReportDashboard services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/history">
                                <ReportingHistoryTableView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/taxAuditRecord">
                                <TaxAuditRecordsView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/tpTaxVarianceRecords">
                                <TPTaxVarianceReportView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/taxAuditRecord/details/:encodedTransactionId&:encodedTaxJournalHeaderId">
                                <TaxAuditRecordDetailsView services={serviceCollection} />
                            </Route>
                            <Route exact path="/agreements">
                                <ATPViewRestrictor module={AppModules.TP_WORKLISTS}><AgreementsView  {...{ services: serviceCollection }} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/agreements/details/:encodedRecordId">
                                <ATPViewRestrictor module={AppModules.TP_WORKLISTS}><AgreementDetails services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations">
                                <ATPViewRestrictor module={AppModules.CALCULATION_MANAGER}><CalculationsLanding /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/search">
                                <ATPViewRestrictor module={AppModules.SEARCH_CALCULATION}>
                                    <SearchCalculations {...{ services: serviceCollection, userProfile: userProfile as UserProfile }} />
                                </ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/builder/:encodedCliRecordId">
                                <ATPViewRestrictor module={AppModules.CALCULATION_BUILDER}>
                                    <CalculationBuilderView {...{ services: serviceCollection, isICRSRecord: false }} />
                                </ATPViewRestrictor>
                            </Route>
                            <Route exact path="/icrs-calculations/builder/:encodedCliRecordId">
                                <ATPViewRestrictor module={AppModules.CALCULATION_BUILDER}>
                                    <CalculationBuilderView {...{ services: serviceCollection, isICRSRecord: true }} />
                                </ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/readOnly">
                                <ATPViewRestrictor module={AppModules.READ_ONLY_CALC}><ReadOnlyCalculationView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/link">
                                <ATPViewRestrictor module={AppModules.BULK_LINK}><LinkToTemplateView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/clone">
                                <ATPViewRestrictor module={AppModules.BULK_CLONE}><CloneCalculationsView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/customDataTables">
                                <ATPViewRestrictor module={AppModules.CUSTOM_DATA_TABLES}><CustomTablesView services={serviceCollection }/></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/customDataTables/details/:encodedTableId">
                                <ATPViewRestrictor module={AppModules.CUSTOM_DATA_TABLES}><CustomTableRecordsView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/customCoa">
                                <ATPViewRestrictor module={AppModules.CUSTOM_COA}><CustomCOAView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/customCoa/details/:encodedTableId">
                                <ATPViewRestrictor module={AppModules.CUSTOM_COA}><CustomCOARowsView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/tpAllocation">
                                <ATPViewRestrictor module={AppModules.TP_ALLOCATION}><TPAllocationView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/tpAllocation/standalone/:encodedWorksheetId">
                                <ATPViewRestrictor module={AppModules.TP_ALLOCATION}><StandaloneWorksheetView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/tpAllocation/linked/:encodedWorksheetId">
                                <ATPViewRestrictor module={AppModules.TP_ALLOCATION}><LinkedWorksheetView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/tpAllocation/template/:encodedTemplateId">
                                <ATPViewRestrictor module={AppModules.TP_ALLOCATION}><WorksheetTemplateView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/mec">
                                <ATPViewRestrictor module={AppModules.MEC}><MECView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/calculations/balanceReconciliation">
                                <ATPViewRestrictor module={AppModules.PERIOD_CHARGES}><PeriodChargesView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            <Route exact path="/reports/tpTaxReport/:filter?">
                                <TPTaxReportView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/lef/:filter?">
                                <TPTaxLefReportView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/cwb/:filter?">
                                <TPTaxCwbReportView services={serviceCollection} />
                            </Route>
                            <Route exact path="/reports/prevalidation">
                                <ReportsTabbedView services={serviceCollection} />
                            </Route>
                            <Route exact path="/calculations/bulkActions">
                                <ATPViewRestrictor module={AppModules.BULK_ACTIONS}><BulkActionsView services={serviceCollection} /></ATPViewRestrictor>
                            </Route>
                            { isNafnUser ? <Redirect path="*" to="/calculations/readOnly" /> : <Redirect path="*" to="/" /> }
                        </Switch>
            </CustomAppLayout>
        </GlobalContexProvider>
    );
}